import {
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { makeStyles, lighten } from '@material-ui/core/styles'
import clsx from 'clsx'
import { GlobalFilter } from '.'

const useTableHeaderBarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    paddingLeft: theme.spacing(1),
    flex: '1 1 100%',
  },
}))

export const TableHeaderBar = ({
  numSelected,
  setGlobalFilter,
  globalFilter,
  titleText,
  headerButtons,
  selectedHeaderButtons,
}) => {
  const classes = useTableHeaderBarStyles()
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <>
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
          >
            {numSelected} selected
          </Typography>
          {selectedHeaderButtons.map((item) =>
            item.url ? (
              <a href={item.url} target="_blank">
                {item.icon}
              </a>
            ) : (
              <Tooltip title={item.tooltip}>
                <IconButton onClick={item.onClick}>{item.icon}</IconButton>
              </Tooltip>
            )
          )}
          {/* <Tooltip title="Delete">
            <IconButton onClick={deleteHandler}>
              <Delete />
            </IconButton>
          </Tooltip> */}
        </>
      ) : (
        <>
          <Typography
            color="textPrimary"
            variant="h3"
            style={{ marginRight: '25px' }}
          >
            {titleText}
          </Typography>
          {headerButtons.map((item) =>
            item.url ? (
              <Tooltip title={item.tooltip}>
                <IconButton href={item.url}>{item.icon}</IconButton>
                {/* <a
                  href={item.url}
                  style={{ padding: '12px', textDecoration: 'none' }}
                >
                  {item.icon}
                </a> */}
              </Tooltip>
            ) : (
              <Tooltip title={item.tooltip}>
                <IconButton onClick={item.onClick}>{item.icon}</IconButton>
              </Tooltip>
            )
          )}
          {/* {headerButtons.map((item) => (
            // <Tooltip title={item.tooltip}>
              {item.url && (
                <a href={item.url} target="_blank">
                  {item.icon}
                </a>
              )}
              {item.onClick && (
                <IconButton onClick={item.onClick}>{item.icon}</IconButton>
              )}
            // </Tooltip>
          ))} */}
          {/* <Tooltip title="Add">
            <IconButton onClick={addHandler}>
              <Add color="primary" />
            </IconButton>
          </Tooltip> */}
          {/* {multiAddHandler && (
            <Tooltip title="Add Multiple Dialogs">
              <IconButton onClick={multiAddHandler}>
                <PlaylistAdd color="primary" />
              </IconButton>
            </Tooltip>
          )} */}
          <Box flexGrow={1} />
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </>
      )}
    </Toolbar>
  )
}
