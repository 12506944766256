import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { forwardRef, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { TaskCard } from './TaskCard'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const useStyles = makeStyles({
  root: {
    background: 'black',
  },
  input: {
    color: 'white',
    fontSize: 22,
    borderColor: 'white !important',
  },
})

export const TasksDialog = ({
  tasksDialogOpen,
  setTasksDialogOpen,
  taskIds,
  handleEvaluation,
  handleDeactivate,
  handleReset
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  useEffect(() => {
    console.log(`data changed`, taskIds)
  }, [taskIds])

  const handleClose = () => {
    setTasksDialogOpen(false)
  }

  return (
    <Dialog
      fullScreen
      maxWidth="md"
      open={tasksDialogOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <Typography variant="body1" style={{ flex: 1 }}>
            {`${taskIds.length} Tasks`}
          </Typography>
          <Box style={{ flex: 1 }} />
          <IconButton color="inherit" onClick={handleClose}>
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>

      <DialogContent style={{ height: '100%' }}>
        {taskIds.length === 0 && ( // should never reach here
          <Typography variant="h4">
            Congratulations! You have finished all tasks.
          </Typography>
        )}
        {taskIds.length > 0 && (
          <TaskCard
            taskId={taskIds[0]}
            handleEvaluation={handleEvaluation}
            handleDeactivate={handleDeactivate}
            handleReset={handleReset}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
