import EditorJS from '@editorjs/editorjs'
import { useEffect, useRef, useState } from 'react'
import { commonTools } from './commonTools'
import { defaultData1, defaultData2 } from './defaultData'

const EDITORJS_HOLDER_ID = 'editorjs'
export const Editor = ({ initData, onDataChange }) => {
  const ejInstance = useRef()
  // const [data, setData] = useState(defaultData1)
  // const [ready, setReady] = useState(false)

  // console.log(`defaultData2`, JSON.stringify(defaultData2, null, 2))
  // console.log(`in Editor.js: data`, JSON.stringify(data, null, 2))
  useEffect(() => {
    if (!ejInstance.current) {
      initEditor()
    }
    return () => {
      ejInstance.current.destroy()
      ejInstance.current = null
    }
  }, [])

  useEffect(() => {
    console.log(`*** initData in Editor`, initData)
  }, [initData])

  // useEffect(() => {
  //   ;(async () => {
  //     if (initData && ready) {
  //       // setData(initData)
  //       console.log(`initData changed`, JSON.stringify(initData, null, 2))
  //       console.log(`ejInstance.current`, ejInstance.current)
  //       // setImmediate(async () => {
  //       //   console.log(`ejInstance.current`, ejInstance.current)
  //       if (ejInstance.current) {
  //         await ejInstance.current.isReady
  //         await ejInstance.current.clear()
  //         await ejInstance.current.isReady
  //         await ejInstance.current.render(initData)
  //         await ejInstance.current.isReady
  //       }
  //       // })
  //     }
  //   })()
  // }, [initData, ready])

  const initEditor = () => {
    console.log(`*** initEditor`)
    const editor = new EditorJS({
      holder: EDITORJS_HOLDER_ID,
      logLevel: 'ERROR',
      data: initData,
      autofocus: true,
      tools: {
        ...commonTools,
      },
      onReady: () => {
        ejInstance.current = editor
        // setReady(true)
        console.log(`*** RRRRRRRRRRRReady`)
      },
      onChange: () => {
        ejInstance.current.save().then((content) => {
          // setData(content)
          console.log(`Editor onChange content:`, content)
          onDataChange(content)
        })
      },
    })
  }

  return <div id={EDITORJS_HOLDER_ID}> </div>
}

// import EditorJS from '@editorjs/editorjs'
// import { useRef, useState, useEffect } from 'react'
// import { commonTools } from './commonTools'
// import { defaultData, defaultData1 } from './defaultData'

// const EDITORJS_HOLDER_ID = 'editorjs'

// export const Editor = ({ initData, onDataChange, onEditorReady }) => {
//   const ejInstance = useRef()
//   const [ready, setReady] = useState(false)
//   const [data, setData] = useState(null)

//   useEffect(() => {
//     if (!ejInstance.current) {
//       initEditor()
//     }
//     return () => {
//       if (ejInstance.current) {
//         console.log(`****** destroy ejInstance`)
//         ejInstance.current.destroy()
//         ejInstance.current = null
//       }
//     }
//   }, [initData])

//   useEffect(() => {
//     console.log(`****** initData in Editor`, initData)
//   }, [initData])

//   // useEffect(() => {
//   //   console.log(`**** ejInstance.current`, ejInstance.current)
//   // }, [ejInstance.current])

//   // useEffect(() => {
//   //   ;(async () => {
//   //     if (initData && ready) {
//   //       // setData(initData)
//   //       console.log(`initData changed`, JSON.stringify(initData, null, 2))
//   //       console.log(`ejInstance.current`, ejInstance.current)
//   //       // setImmediate(async () => {
//   //       //   console.log(`ejInstance.current`, ejInstance.current)
//   //       if (ejInstance.current) {
//   //         await ejInstance.current.isReady
//   //         await ejInstance.current.clear()
//   //         await ejInstance.current.isReady
//   //         await ejInstance.current.render(initData)
//   //         // await ejInstance.current.isReady
//   //       }
//   //       // })
//   //     }
//   //   })()
//   // }, [initData, ready])

//   const initEditor = () => {
//     console.log(`****** initEditor ****, initData: `, initData)
//     // setReady(false)
//     const editor = new EditorJS({
//       holder: EDITORJS_HOLDER_ID,
//       logLevel: 'ERROR',
//       autofocus: true,
//       data: initData,
//       tools: {
//         ...commonTools,
//       },
//       onReady: () => {
//         ejInstance.current = editor
//         console.log(
//           `****** onReady: ejInstance.current ******`,
//           ejInstance.current
//         )
//         // setReady(true)
//         // onEditorReady()
//       },
//       onChange: () => {
//         // // let content = await this.editorjs.saver.save()
//         // let content = await ejInstance.current.save()
//         // // Put your logic here to save this data to your DB
//         // onDataChange(content)
//         // setData(content)
//         // // setEditorData(content);
//         console.log(
//           `****** onChange: ejInstance.current ******`,
//           ejInstance.current
//         )
//         // // ejInstance.current.isReady.then(() => {
//         ejInstance.current.save().then((content) => {
//           console.log(
//             `****** onChange saved: ejInstance.current ******`,
//             ejInstance.current
//           )
//           // setData(content)
//           onDataChange(content)
//         })
//         // })
//         // console.log(`onChange`)
//         // let content = ejInstance.current.save()
//         // console.log(`content`, content)
//         // await ejInstance.current.isReady
//         // console.log(`isReady`)
//         // onDataChange(content)
//       },
//     })
//   }

//   return <div id={EDITORJS_HOLDER_ID}> </div>
// }
