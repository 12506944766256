import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dismissConfirmation } from './ducks'

export const Confirmation = () => {
  const dispatch = useDispatch()
  const [turnOff, setTurnOff] = useState(false)
  const {
    open,
    params: { title, content, okTitle, cancelTitle, children },
  } = useSelector((state) => state.confirmation)

  const handleClose = () => {
    dispatch(dismissConfirmation({ result: 'cancel' }))
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <Typography variant="h3" color="primary">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
        {children}
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={turnOff}
              onChange={(e) => setTurnOff(!turnOff)}
              // name="turnOff"
            />
          }
          label="Don't show this again"
        /> */}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(dismissConfirmation({ result: 'ok' }))
          }}
          color="primary"
        >
          {okTitle}
        </Button>
        <Button
          onClick={() => {
            dispatch(dismissConfirmation({ result: 'cancel' }))
          }}
          color="primary"
          autoFocus
        >
          {cancelTitle}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export * from './ducks'
