import { useSelector } from 'react-redux'
import { Navigate, Route, useLocation } from 'react-router-dom'
import { LOCAL_DEBUG, rootPath } from '../../../config'
import { isLoggedIn } from '../ducks'

// const rootPath = LOCAL_DEBUG ? '' : '/notes'

export const PrivateRouter = ({ element, redirect, ...rest }) => {
  const location = useLocation()
  const loggedIn = useSelector(isLoggedIn)
  return (
    <Route
      {...rest}
      element={
        loggedIn ? (
          element
        ) : (
          <Navigate
            to={redirect || `${rootPath}/login`}
            state={{ from: location.pathname }}
            replace={true}
          />
        )
      }
    />
  )
}
