import { Container, IconButton, Tooltip } from '@material-ui/core'
import {
  Add,
  AddCircleOutline,
  Alarm,
  AlarmOff,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  Delete,
  EditOutlined,
  PostAdd,
} from '@material-ui/icons'
import { format } from 'date-fns/esm'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormPage, Page, useSharedStyles } from '../../styles'
import { setConfirmationParams, showConfirmation } from '../utilities'
import { defaultData2, defaultData3 } from '../utilities/editor/defaultData'
import { DataTable, TableCheckbox } from '../utilities/table'
import {
  clearCurrentNote,
  createNote,
  deleteNote,
  fetchANote,
  fetchNotes,
  setTablePageSize,
  updateNote,
} from './ducks'
import { NotesEditorDialog } from './NotesEditorDialog'
import { BASE_URL, LOCAL_DEBUG, NOTES_ENDPOINT } from '../../config'
import { FileUploadDialog } from './FileUploadDialog'
import { ImportDialog } from './ImportDialog'

const COLUMNS = [
  {
    Header: 'Title',
    accessor: 'title',
    Cell: ({ value }) => value || '[No Title]',
  },
  {
    Header: 'Last Modified',
    accessor: 'updatedAt',
    Cell: ({ value }) =>
      value
        ? format(new Date(value), 'MM/dd/yyyy')
        : format(new Date(), 'MM/dd/yyyy'),
    disableFilters: true,
  },
]

// const endpoint = LOCAL_DEBUG ? NOTES_ENDPOINT : `${BASE_URL}${NOTES_ENDPOINT}`

export const NotePage = () => {
  const classes = useSharedStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const data = useSelector((state) => state.notes.notes)
  const [selectedNotes, setSelectedNotes] = useState([])

  const [editorOpen, setEditorOpen] = useState(false)
  const [noteData, setNoteData] = useState(null)

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  // Update notes on mount and each time the editor closes
  useEffect(() => {
    if (!editorOpen) dispatch(fetchNotes({}))
  }, [editorOpen])

  const columns = useMemo(() => COLUMNS, [])
  const tablePageSize = useSelector((state) => state.notes.tablePageSize)

  const toggleArchive = (note) => {
    dispatch(
      updateNote({
        id: note._id,
        note: { active: !note.active },
        done: () => {
          setImmediate(() => {
            dispatch(fetchNotes({}))
          })
        },
      })
    )
  }

  const hookFunction = (hooks) => {
    hooks.allColumns.push((columns) => [
      ...columns,
      {
        id: 'edit',
        Header: '',
        Cell: ({ row }) => (
          <span style={{ whiteSpace: 'nowrap' }}>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  // navigate(`editor/${row.original._id}`)
                  openNoteEditor(row.original._id)
                }}
              >
                <EditOutlined color="primary" />
              </IconButton>
            </Tooltip>
            {row.original.active && (
              <Tooltip title="Archive">
                <IconButton
                  onClick={() => {
                    dispatch(
                      setConfirmationParams({
                        title: 'Waring',
                        content:
                          'All tasks of an archived note will not be scheduled for future review. Do you want to continue?\r\n\r\n',
                        okTitle: 'Archive Note',
                        okCallback: () => {
                          setImmediate(() => {
                            toggleArchive(row.original)
                          })
                        },
                      })
                    )
                    dispatch(showConfirmation())
                  }}
                >
                  <AlarmOff color="primary" />
                </IconButton>
              </Tooltip>
            )}
            {!row.original.active && (
              <Tooltip title="Unarchive">
                <IconButton onClick={() => toggleArchive(row.original)}>
                  <Alarm color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </span>
        ),
      },
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <TableCheckbox {...getToggleAllRowsSelectedProps()} />
        ),
        Cell: ({ row }) => (
          <TableCheckbox {...row.getToggleRowSelectedProps()} />
        ),
      },
    ])
  }

  const handleSelectedFlatRows = (selectedFlatRows) => {
    const noteIds = selectedFlatRows.map((row) => row.original._id)
    setSelectedNotes(noteIds)
  }

  const handleChangeRowsPerPage = (tablePageSize) => {
    dispatch(setTablePageSize({ tablePageSize }))
  }

  // const handleDeleting = () => {
  //   for (let i = 0; i < selectedNotes.length; i++) {
  //     // console.log(`to delete: selectedNotes[i]`, selectedNotes[i])
  //     dispatch(
  //       deleteNote({
  //         id: selectedNotes[i],
  //         done: () => {
  //           dispatch(fetchNotes({}))
  //         },
  //       })
  //     )
  //   }
  //   // dispatch(fetchNotes({}))
  // }

  const handleDeleting = () => {
    dispatch(
      setConfirmationParams({
        title: 'Confirm Deleting',
        content:
          'Selected item(s) will be deleted permanently, do you want to continue?\r\n\r\n',
        okCallback: () => {
          setImmediate(() => {
            for (let i = 0; i < selectedNotes.length; i++) {
              // console.log(`to delete: selectedNotes[i]`, selectedNotes[i])
              dispatch(
                deleteNote({
                  id: selectedNotes[i],
                  done: () => {
                    dispatch(fetchNotes({}))
                  },
                })
              )
            }
          })
        },
      })
    )
    dispatch(showConfirmation())
  }
  const openNoteEditor = (id) => {
    if (id) {
      dispatch(
        fetchANote({
          id,
          done: (resBody) => {
            // !!! resBody could not be used directly as a plain object. Here it would lead to failure of writing `this._data.text`.
            console.log(`resBody in openNodeEditor`, resBody)
            const jstr = JSON.stringify(resBody)
            const newBody = JSON.parse(jstr)
            setNoteData(newBody)
            setEditorOpen(true)
            // setNoteData(resBody)
            // setEditorOpen(true)
          },
        })
      )
    } else {
      // setNoteData(null)
      dispatch(
        createNote({
          note: {
            time: Date.now(),
            version: '2.20.2',
            blocks: [],
            title: '',
            active: true,
          },
          done: (resBody) => {
            // !!! resBody could not be used directly as a plain object. Here it would lead to failure of writing `this._data.text`.
            console.log(`resBody in openNodeEditor`, resBody)
            const jstr = JSON.stringify(resBody)
            const newBody = JSON.parse(jstr)
            setNoteData(newBody)
            setEditorOpen(true)
            // setNoteData(resBody)
            // setEditorOpen(true)
          },
        })
      )

      // setEditorOpen(true)
    }
  }

  const headerButtons = [
    {
      tooltip: 'New Note',
      onClick: () => {
        console.log(`Add onClick`)
        openNoteEditor()
      },
      icon: <PostAdd color="primary" />,
    },
    {
      tooltip: 'Export All Notes',
      // url: `${endpoint}/export`,
      // url: `http://localhost:3999/api/notes/export`,
      // url: `http://134.122.35.174:3999/api/notes/export`,
      url: `${BASE_URL}/api/notes/export`,
      // onClick: () => {
      //   // navigate(`${endpoint}/export`)
      //   const url = `${endpoint}/export`
      //   console.log(`url`, url)
      //   window.location.href = url
      // },
      icon: <CloudDownloadOutlined color="primary" />,
    },
    {
      tooltip: 'Import Notes',
      onClick: () => {
        console.log(`Import notes`)
        setUploadDialogOpen(true)
      },
      icon: <CloudUploadOutlined color="primary" />,
    },
  ]

  const selectedHeaderButtons = [
    {
      tooltip: 'Delete',
      onClick: () => {
        console.log(`Delete onClick`)
        handleDeleting()
      },
      icon: <Delete />,
    },
    // {
    //   tooltip: 'Add oh oh',
    //   onClick: () => {
    //     console.log(`Add onClick`)
    //   },
    //   icon: <Add color="primary" />,
    // },
  ]

  return (
    <Page className={classes.formpage} title="Notes">
      <Container maxWidth="lg">
        <DataTable
          columns={columns}
          data={data}
          tablePageSize={tablePageSize}
          hookFunction={hookFunction}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSelectedFlatRows={handleSelectedFlatRows}
          handleDeleting={handleDeleting}
          maxWidth={false}
          hasTableToolbar={true}
          selectedItemNames={selectedNotes}
          titleText="Notes"
          subTitleText="Manage your notes here"
          addHandler={() => {
            // dispatch(clearCurrentNote())
            // navigate('editor')
            openNoteEditor(null)
          }}
          multiAddHandler={() => {}}
          tableSize="small"
          isRowSelected={() => false}
          onRowDoubleClick={(row) => {
            // navigate(`editor/${row.original._id}`)
            openNoteEditor(row.original._id)
          }}
          selectedHeaderButtons={selectedHeaderButtons}
          headerButtons={headerButtons}
        />
      </Container>
      {editorOpen && (
        <NotesEditorDialog
          initData={noteData}
          editorOpen={editorOpen}
          setEditorOpen={setEditorOpen}
          onChange={(data) => console.log(`editor dialog data changed`, data)}
        />
      )}
      {/* <FileUploadDialog
        uploadDialogOpen={uploadDialogOpen}
        setUploadDialogOpen={setUploadDialogOpen}
      /> */}
      <ImportDialog
        importOpen={uploadDialogOpen}
        setImportOpen={setUploadDialogOpen}
      />
    </Page>
  )
}
