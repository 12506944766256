import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { authReducer } from '../features/auth'
import { blocksReducer } from '../features/blocks'
import { notesReducer } from '../features/notes'
import {
  confirmationReducer,
  progressReducer,
  toastReducer,
} from '../features/utilities'

export const store = configureStore({
  reducer: {
    progress: progressReducer,
    toast: toastReducer,
    auth: authReducer,
    notes: notesReducer,
    blocks: blocksReducer,
    confirmation: confirmationReducer,
  },
  // middleware: [...getDefaultMiddleware(), logger],
  middleware: [thunk, logger],
})
