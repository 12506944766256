import { Checkbox, InputAdornment, TextField } from '@material-ui/core'
import { SearchOutlined } from '@material-ui/icons'
import { forwardRef, useEffect, useRef } from 'react'

export const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        {/* <input type="checkbox" ref={resolvedRef} {...rest} /> */}
        <Checkbox ref={resolvedRef} {...rest} />
      </>
    )
  }
)

export const TableCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef()
  const resolvedRef = ref || defaultRef
  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])
  // return (
  //   <input
  //     type="checkbox"
  //     ref={resolvedRef}
  //     style={{ width: '48px', height: '48px' }}
  //     {...rest}
  //   />
  // )
  return <Checkbox ref={resolvedRef} {...rest} />
})

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <TextField
      value={filter || ''}
      onChange={(e) => setFilter(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        ),
      }}
    />
  )
}

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column
  return (
    <span>
      Search:{' '}
      <input
        value={filterValue || ''}
        onChange={(e) => setFilter(e.target.value)}
      />
    </span>
  )
}

export * from './TablePaginationActions'
export * from './TableHeaderBar'
export * from './DataTable'
export * from './SelectorTable'
