import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BASE_URL, LOCAL_DEBUG, NOTES_ENDPOINT } from '../../config'
import { reqBody, thunkFetch } from '../utilities/thunkUtilities'

const endpoint = LOCAL_DEBUG ? NOTES_ENDPOINT : `${BASE_URL}${NOTES_ENDPOINT}`

export const fetchNotes = createAsyncThunk(
  'notes/fetchNotes',
  async ({ user, done }, thunkAPI) => {
    return thunkFetch({
      thunkAPI,
      message: `Fetching notes...`,
      errMessage: `Failed fetching notes`,
      url: endpoint,
      done,
    })
  }
)

export const fetchANote = createAsyncThunk(
  'notes/fetchANote',
  async ({ id, done }, thunkAPI) => {
    return thunkFetch({
      thunkAPI,
      message: `Fetching note...`,
      errMessage: `Failed fetching note`,
      url: `${endpoint}/${id}`,
      done,
    })
  }
)

export const createNote = createAsyncThunk(
  'notes/createNote',
  async ({ note, done }, thunkAPI) => {
    return thunkFetch({
      thunkAPI,
      message: `Creating new note...`,
      errMessage: `Failed creating new note`,
      url: endpoint,
      body: reqBody('POST', note),
      done: (resBody) => {
        console.log(`resBody`, resBody)
        thunkAPI.dispatch(
          // setCurrentNote({ note: resBody })
          setCurrentNote({ note: JSON.parse(JSON.stringify(resBody)) })
        )
        setImmediate(() => {
          if (done) done(resBody)
        })
      },
    })
  }
)

export const importNotes = createAsyncThunk(
  'notes/importNotes',
  async ({ notes, done }, thunkAPI) => {
    return thunkFetch({
      thunkAPI, 
      message: `Importing notes...`,
      errMessage: `Failed importing notes`,
      url: `${endpoint}/import`,
      body: reqBody('POST', notes),
      done
    })
  }
)

export const updateNote = createAsyncThunk(
  'notes/updateNote',
  async ({ id, note, done }, thunkAPI) => {
    return thunkFetch({
      thunkAPI,
      // message: `Uploading note...`,
      errMessage: `Failed uploading note`,
      url: `${endpoint}/${id}`,
      body: reqBody('PUT', note),
      done,
    })
  }
)

export const deleteNote = createAsyncThunk(
  'notes/deleteANote',
  async ({ id, done }, thunkAPI) => {
    return thunkFetch({
      thunkAPI,
      message: `Deleteing note...`,
      errMessage: `Failed deleting note`,
      url: `${endpoint}/${id}`,
      body: reqBody('DELETE'),
      done,
    })
  }
)

const notesSlice = createSlice({
  name: 'notes',
  initialState: {
    notes: [],
    currentNote: null,
    tablePageSize: 10,
  },
  reducers: {
    setTablePageSize: (state, action) => {
      state.tablePageSize = action.payload.tablePageSize
    },
    setCurrentNote: (state, action) => {
      state.currentNote = action.payload.note
    },
    clearCurrentNote: (state, action) => {
      state.currentNote = null
    },
  },
  extraReducers: {
    [fetchNotes.fulfilled]: (state, action) => {
      state.notes = action.payload
    },
    [fetchNotes.rejected]: (state, action) => {
      console.log(`fetchNotes rejected`, JSON.stringify(action, null, 2))
      state.notes = []
    },
    [fetchANote.fulfilled]: (state, action) => {
      state.currentNote = action.payload
    },
    [fetchANote.rejected]: (state, action) => {
      state.currentNote = null
    },
  },
})

export const notesReducer = notesSlice.reducer
export const { setTablePageSize, setCurrentNote, clearCurrentNote } =
  notesSlice.actions
