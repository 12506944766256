export class MarkerTool {
  // Constructor:
  constructor({ api }) {
    this.api = api
    this.button = null
    this._state = false
    this.tag = 'MARK'
    this.class = 'cdx-marker'
  }

  // static functions: isIncline and sanitize
  static get isInline() {
    return true
  }

  static get sanitize() {
    return {
      mark: {
        class: 'cdx-marker',
      },
    }
  }

  // basic get/set functions: state and shortcut
  get shortcut() {
    return 'CMD+M'
  }

  get state() {
    return this._state
  }

  set state(state) {
    this._state = state
    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state)
  }

  // render function: return the toolbar button
  render() {
    this.button = document.createElement('button')
    this.button.type = 'button'
    this.button.innerHTML =
      '<svg width="20" height="18"><path d="M10.458 12.04l2.919 1.686-.781 1.417-.984-.03-.974 1.687H8.674l1.49-2.583-.508-.775.802-1.401zm.546-.952l3.624-6.327a1.597 1.597 0 0 1 2.182-.59 1.632 1.632 0 0 1 .615 2.201l-3.519 6.391-2.902-1.675zm-7.73 3.467h3.465a1.123 1.123 0 1 1 0 2.247H3.273a1.123 1.123 0 1 1 0-2.247z"/></svg>'
    this.button.classList.add(this.api.styles.inlineToolButton)
    return this.button
  }

  // surround function: toggle the target effect based on the current state when user click the tool bar button
  surround(range) {
    if (this.state) {
      this.unwrap(range)
    } else {
      this.wrap(range)
    }
  }

  wrap(range) {
    const selectedText = range.extractContents()
    const mark = document.createElement(this.tag)

    mark.classList.add(this.class)
    mark.appendChild(selectedText)
    range.insertNode(mark)

    this.api.selection.expandToTag(mark)
  }

  unwrap(range) {
    // Method 1: expand the selection to the whole 'mark' tag, extract the text and create a new document fragement from the text.
    // const mark = this.api.selection.findParentTag(this.tag, this.class)
    // if (mark) {
    //   this.api.selection.expandToTag(mark)
    //   console.log(`mark`, mark)
    //   const text = document
    //     .createRange()
    //     .createContextualFragment(mark.innerText)
    //   mark.remove()
    //   range.insertNode(text)
    // }

    // Method 2: expand the selection to the whole 'mark' in `checkState` method (see below)
    const mark = this.api.selection.findParentTag(this.tag)
    const text = range.extractContents()
    mark.remove()
    range.insertNode(text)
  }

  // checkState function: update this._state based on the selection
  checkState() {
    const mark = this.api.selection.findParentTag(this.tag)
    // Method 2:
    if (mark) {
      this.api.selection.expandToTag(mark)
    }
    this.state = !!mark
    // if (this.state) {
    //   this.showActions(mark)
    // } else {
    //   this.hideActions()
    // }
  }

  // user action functions: additional toolbar items

  renderActions() {
    this.colorPicker = document.createElement('input')
    this.colorPicker.type = 'color'
    this.colorPicker.value = '#f5f1cc'
    this.colorPicker.hidden = true
    return this.colorPicker
  }

  showActions(mark) {
    const { backgroundColor } = mark.style
    this.colorPicker.value = backgroundColor
      ? this.convertToHex(backgroundColor)
      : '#f5f1cc'
    this.colorPicker.onchange = () => {
      mark.style.backgroundColor = this.colorPicker.value
    }
    this.colorPicker.hidden = false
  }

  hideActions() {
    this.colorPicker.onchange = null
    this.colorPicker.hidden = true
  }

  convertToHex(color) {
    const rgb = color.match(/(\d+)/g)
    let hexr = parseInt(rgb[0]).toString(16)
    let hexg = parseInt(rgb[1]).toString(16)
    let hexb = parseInt(rgb[2]).toString(16)

    hexr = hexr.length === 1 ? '0' + hexr : hexr
    hexg = hexg.length === 1 ? '0' + hexg : hexg
    hexb = hexb.length === 1 ? '0' + hexb : hexb

    return '#' + hexr + hexg + hexb
  }

  clear() {
    this.hideActions()
  }
}

// export class MarkerTool {
//   constructor({ api }) {
//     this.api = api
//     this.button = null
//     this._state = false
//     this.tag = 'MARK'
//     this.class = 'cdx-marker'
//   }

//   static get isInline() {
//     return true
//   }

//   static get sanitize() {
//     return {
//       mark: {
//         class: 'cdx-marker',
//       },
//     }
//   }

//   get shortcut() {
//     return 'CMD+M'
//   }

//   get state() {
//     return this._state
//   }

//   set state(state) {
//     this._state = state
//     this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state)
//   }

//   render() {
//     this.button = document.createElement('button')
//     this.button.type = 'button'
//     this.button.innerHTML =
//       '<svg width="20" height="18"><path d="M10.458 12.04l2.919 1.686-.781 1.417-.984-.03-.974 1.687H8.674l1.49-2.583-.508-.775.802-1.401zm.546-.952l3.624-6.327a1.597 1.597 0 0 1 2.182-.59 1.632 1.632 0 0 1 .615 2.201l-3.519 6.391-2.902-1.675zm-7.73 3.467h3.465a1.123 1.123 0 1 1 0 2.247H3.273a1.123 1.123 0 1 1 0-2.247z"/></svg>'
//     this.button.classList.add(this.api.styles.inlineToolButton)
//     return this.button
//   }

//   surround(range) {
//     if (this.state) {
//       this.unwrap(range)
//     } else {
//       this.wrap(range)
//     }
//   }

//   wrap(range) {
//     const selectedText = range.extractContents()
//     const mark = document.createElement(this.tag)

//     mark.classList.add(this.class)
//     mark.appendChild(selectedText)
//     range.insertNode(mark)

//     this.api.selection.expandToTag(mark)
//   }

//   unwrap(range) {
//     const mark = this.api.selection.findParentTag(this.tag)
//     if (mark) {
//       this.api.selection.expandToTag(mark)
//     }
//     this.state = !!mark
//     if (this.state) {
//       this.showActions(mark)
//     } else {
//       this.hideActions()
//     }
//   }

//   renderActions(mark) {
//     this.colorPicker = document.createElement('input')
//     this.colorPicker.type = 'color'
//     this.colorPicker.value = '#f5f1cc'
//     this.colorPicker.hidden = true
//     return this.colorPicker
//   }

//   showActions(mark) {
//     const { backgroundColor } = mark.style
//     this.colorPicker.value = backgroundColor
//       ? this.convertToHex(backgroundColor)
//       : '#f5f1cc'
//     this.colorPicker.onchange = () => {
//       mark.style.backgroundColor = this.colorPicker.value
//     }
//     this.colorPicker.hidden = false
//   }

//   hideActions() {
//     this.colorPicker.onchange = null
//     this.colorPicker.hidden = true
//   }

//   convertToHex(color) {
//     const rgb = color.match(/(\d+)/g)
//     let hexr = parseInt(rgb[0]).toString(16)
//     let hexg = parseInt(rgb[1]).toString(16)
//     let hexb = parseInt(rgb[2]).toString(16)

//     hexr = hexr.length === 1 ? '0' + hexr : hexr
//     hexg = hexg.length === 1 ? '0' + hexg : hexg
//     hexb = hexb.length === 1 ? '0' + hexb : hexb

//     return '#' + hexr + hexg + hexb
//   }

//   clear() {
//     this.hideActions()
//   }
// }
