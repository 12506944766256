export class SimpleAudio {
  constructor({ data, api, config }) {
    this.api = api
    this.config = config || {}
    this.data = {
      url: data.url || '',
      caption: data.caption || '',

      _id: data._id,
      history: data.history,
      schedule: data.schedule,
    }
    this.wrapper = undefined
  }

  static get toolbox() {
    return {
      title: 'Audio',
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 3v9.28c-.47-.17-.97-.28-1.5-.28C8.01 12 6 14.01 6 16.5S8.01 21 10.5 21c2.31 0 4.2-1.75 4.45-4H15V6h4V3h-7z"/></svg>',
    }
  }

  static get pasteConfig() {
    return {
      patterns: {
        audio: /https?:\/\/\S+\.(mp3|wav|ogg)$/i,
      },
    }
  }

  onPaste(e) {
    switch (e.type) {
      case 'pattern':
        const src = e.detail.data
        this._createAudio(src)
        break
      default:
        break
    }
  }

  static get sanitize() {
    return {
      url: {},
      caption: {
        b: true,
        a: {
          href: true,
        },
        i: true,
      },
    }
  }

  render() {
    this.wrapper = document.createElement('div')
    this.wrapper.classList.add('simple-audio')
    if (this.data && this.data.url) {
      this._createAudio(this.data.url, this.data.caption)
      return this.wrapper
    }
    const input = document.createElement('input')
    input.placeholder = this.config.placeholder || 'Paste an audio URL...'
    input.addEventListener('paste', (e) => {
      this._createAudio(e.clipboardData.getData('text'))
    })
    this.wrapper.appendChild(input)
    return this.wrapper
  }

  _createAudio(url, catpionText) {
    const audio = document.createElement('audio')
    audio.width = 480
    audio.controls = true
    const source = document.createElement('source')
    source.src = url
    source.type = 'audio/mp3'
    audio.appendChild(source)
    const caption = document.createElement('div')
    caption.contentEditable = true
    caption.innerHTML = catpionText || ''

    this.wrapper.innerHTML = ''
    this.wrapper.appendChild(audio)
    this.wrapper.appendChild(caption)
  }

  save(blockContent) {
    const audio = blockContent.querySelector('audio')
    const source = blockContent.querySelector('audio source')
    const caption = blockContent.querySelector('[contenteditable]')
    return Object.assign(this.data, {
      url: source ? source.src || '' : '',
      caption: caption ? caption.innerHTML || '' : '',
    })
  }

  validate(savedData) {
    if (!savedData.url.trim()) return false
    return true
  }
}
