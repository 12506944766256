import { Box } from '@material-ui/core'
import { forwardRef } from 'react'
import { Helmet } from 'react-helmet'
import { useSharedStyles } from './sharedStyles'

export const Page = forwardRef(({ children, title = '', ...rest }, ref) => (
  <div ref={ref} {...rest}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </div>
))

export const FormPage = ({ children, title = '' }) => {
  const classes = useSharedStyles()
  return (
    <Page className={classes.formpage} title={title}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {children}
      </Box>
    </Page>
  )
}
