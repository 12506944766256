import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Divider,
  IconButton,
  LinearProgress,
  makeStyles,
  Popover,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core'
import {
  AlarmOff,
  Image,
  Replay,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSharedStyles } from '../../styles'
import { setConfirmationParams, showConfirmation } from '../utilities'
import { useKeypress } from '../utilities/hooks/useKeyPress'
import { activeTask, resetTask, fetchABlock } from './ducks'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  card: {
    alignSelf: 'center',
    fontSize: '18px',
    lineHeight: 1.8,
    maxHeight: '640px',
    overflow: 'auto',
    padding: '10px',
  },
  mediaContainer: {
    // position: 'relative',
    width: '100%',
    height: '100%',
    paddingBottom: '56.25%',
  },
  media: {
    objectFit: 'contain',
  },
})

const maskContent = (contentString, showContent) => {
  const fontSize = '22px'
  const addStyles = [
    `<span style="
      color: #aaa; 
      background-color: #aaa;
      font-size: ${fontSize};
      line-height: 1.8;
    ">`,
    `</span>`,
  ]
  // #f5f1cc; d4ff32
  const showStyles = [
    `<span style="
      background-color: #e9ff32;
      font-size: ${fontSize};
      line-height: 1.8;
    ">`,
    `</span>`,
  ]

  let targetString = contentString
  while (/<mark class="cdx-marker">.*?<\/mark>/gim.test(targetString)) {
    targetString = targetString.replace(
      `<mark class="cdx-marker">`,
      showContent ? showStyles[0] : addStyles[0]
    )
    targetString = targetString.replace(
      `</mark>`,
      showContent ? showStyles[1] : addStyles[1]
    )
  }
  return (
    <div
      style={{
        fontSize,
        lineHeight: 1.8,
        maxHeight: '640px',
        overflow: 'auto',
        padding: '10px',
      }}
      dangerouslySetInnerHTML={{ __html: targetString }}
    />
  )
}

const LinearProgressWithLabel = ({ value, onClick, resetTaskStage }) => {
  return (
    <Box display="flex" alignItems="center">
      {/* <Box display="flex"> */}
      <Box mr={1}>
        <Button color="primary" onClick={onClick}>
          Progress
        </Button>
        {/* <Typography variant="body2" color="textSecondary">
          Progress
        </Typography> */}
      </Box>
      <Box width="120px" mr={1}>
        <LinearProgress variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
      <Tooltip title="Reset Progress">
        <IconButton onClick={resetTaskStage} color="primary">
          <Replay />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

const TaskContent = ({ block, showContent, setWithMasks }) => {
  const classes = useStyles()

  setWithMasks(false)

  if (!block) {
    return <Typography variant="h2">Empty</Typography>
  }

  // setWithMasks(false)

  switch (block.type) {
    case 'paragraph':
      setWithMasks(true)
      return maskContent(block.data.text, showContent)

    case 'list':
      setWithMasks(true)
      let content = block.data.style === 'ordered' ? '<ol>' : '<ul>'
      block.data.items.forEach((item) => (content += `<li>${item}</li>`))
      content += block.data.styles === 'ordered' ? '</ol>' : '</ul>'
      return maskContent(content, showContent)

    case 'code':
      return (
        <div className={classes.card}>
          <pre>
            <code>{block.data.code}</code>
          </pre>
        </div>
      )

    case 'image':
      return (
        <div className={classes.card}>
          <Typography variant="h4" gutterBottom>
            {block.data.caption}
          </Typography>
          <img
            // height="80%"
            style={{ marginTop: '32px' }}
            width="560"
            objectFit="contain"
            src={block.data.url}
            alt="Image Not Available"
          />
        </div>
      )

    case 'video':
      return (
        <div className={classes.card}>
          <video width="560" controls autoPlay>
            <source src={block.data.url} />
            Your browser does not support HTML video.
          </video>
        </div>
      )

    case 'audio':
      return (
        <div className={classes.card}>
          <audio width="560" controls autoPlay>
            <source src={block.data.url} />
            Your browser does not support audio element.
          </audio>
        </div>
      )

    case 'embed':
      if (block.data.service !== 'youtube') {
        return <Typography variant="h2">Empty</Typography>
      }
      return (
        <div
          className={classes.card}
          // style={{
          //   mozUserSelect: 'none',
          //   webkitUserSelect: 'none',
          //   msUserSelect: 'none',
          //   userSelect: 'none',
          // }}
          // unselectable="on"
          // onSelectStart={() => {
          //   return false
          // }}
          // onMouseDown={() => {
          //   return false
          // }}
        >
          {/* <div className={classes.mediaContainer}> */}
          <iframe
            // position="absolute"
            // top="0"
            // left="0"
            // width="100%"
            // height="100%"
            width="560"
            height="315"
            src={block.data.embed}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          {/* </div> */}
        </div>
      )

    default:
      return <Typography variant="h2">Format not supported (yet)</Typography>
  }
}

export const TaskCard = ({
  taskId,
  handleEvaluation,
  handleDeactivate,
  handleReset,
}) => {
  const dispatch = useDispatch()
  const classes = useSharedStyles()
  const theme = useTheme()
  const block = useSelector((state) => state.blocks.currentBlock)
  const [showContent, setShowContent] = useState(false)
  const [withMasks, setWithMasks] = useState(false)

  const [anchorEl, setAnchorEl] = useState(null)
  const handleProgressClick = (e) => setAnchorEl(e.currentTarget)
  const handleProgressClose = () => setAnchorEl(null)
  const progressOpen = Boolean(anchorEl)
  const id = progressOpen ? 'popover' : undefined

  const evaluate = (value) => {
    // console.log(`Self evaluated:`, value)
    // console.log(`block`, block)
    handleEvaluation(block._id, value)
    setShowContent(false)
  }

  useEffect(() => {
    dispatch(fetchABlock({ id: taskId }))
  }, [taskId])

  useKeypress('Escape', () => {
    console.log('Up key pressed')
  })

  const unMask = () => {
    setShowContent(!showContent)
  }

  const deactivateTask = () => {
    dispatch(
      setConfirmationParams({
        title: 'Waring: Task Will Be Deactivated',
        content:
          'The task will be deactivated and will not be scheduled for future review. Do you want to continue?\r\n\r\n',
        okTitle: 'Deactivate Task',
        okCallback: () => {
          console.log(`deactivate taskId`, taskId)
          setImmediate(() => {
            dispatch(
              activeTask({
                id: taskId,
                active: false,
                done: () => {
                  handleDeactivate(taskId)
                  console.log(`deactivated`, taskId)
                },
              })
            )
          })
        },
      })
    )
    dispatch(showConfirmation())
  }

  const resetTaskStage = () => {
    dispatch(
      setConfirmationParams({
        title: 'Waring: Task Progress Will Be Reset',
        content:
          'The task will be reset to stage 0. Do you want to continue?\r\n\r\n',
        okTitle: 'Reset Task',
        okCallback: () => {
          console.log(`reset taskId`, taskId)
          setImmediate(() => {
            dispatch(
              resetTask({
                id: taskId,
                done: () => {
                  handleReset(taskId)
                  console.log(`reset`, taskId)
                },
              })
            )
          })
        },
      })
    )
    dispatch(showConfirmation())
  }

  console.log(`block`, block)
  // const history = []
  // block.data.history.forEach((item) => {
  //   history.push(`${item.evaluation} - ${new Date(item.time).toLocaleString()}`)
  // })
  return (
    <Container maxWidth="lg" className={classes.taskContainer}>
      <TaskContent
        block={block}
        showContent={showContent}
        setWithMasks={setWithMasks}
      />
      <Box flexGrow={1} />
      <Box display="flex" justifyContent="space-around" alignItems="center">
        <LinearProgressWithLabel
          value={block ? block.data.stage * 10 : 0}
          onClick={handleProgressClick}
          resetTaskStage={resetTaskStage}
        />
        <Box flexGrow={1} />

        <Box mr={3}>
          <Tooltip title="Archive This Item">
            <IconButton onClick={deactivateTask} color="primary">
              <AlarmOff />
            </IconButton>
          </Tooltip>
        </Box>
        <Tooltip title="Self Evaluation">
          <ButtonGroup
            size="large"
            color="primary"
            variant="text"
            style={{
              alignSelf: 'center',
              marginLeft: '20px',
              marginRight: '20px',
            }}
          >
            <Button onClick={() => evaluate(1)}>1</Button>
            <Button onClick={() => evaluate(2)}>2</Button>
            <Button onClick={() => evaluate(3)}>3</Button>
            <Button onClick={() => evaluate(4)}>4</Button>
            <Button onClick={() => evaluate(5)}>5</Button>
          </ButtonGroup>
        </Tooltip>

        <Tooltip title="Toggle Highlighter Mask">
          <IconButton onClick={unMask} color="primary" disabled={!withMasks}>
            {showContent ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Tooltip>
      </Box>
      <Popover
        id={id}
        open={progressOpen}
        anchorEl={anchorEl}
        onClose={handleProgressClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {block && (
          <Box m={2}>
            <Box mb={1}>
              <Typography variant="h4" color="textPrimary">
                Learning History
              </Typography>
              <Divider />
            </Box>

            {block.data.history &&
              block.data.history.map((item) => (
                <Typography variant="body2" color="textSecondary">{`${
                  item.evaluation
                } - ${new Date(item.time).toLocaleString()}`}</Typography>
              ))}
          </Box>
        )}
      </Popover>
    </Container>
  )
}
