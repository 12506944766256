import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { changePassword } from '../ducks'

const validationSchema = Yup.object({
  password: Yup.string()
    .min(8)
    .max(128)
    .required('Current password is required'),
  newPassword: Yup.string()
    .min(8)
    .max(128)
    .required('New password is required'),
  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Password must match')
    .required('Please re-enter the new password'),
})

export const PasswordDialog = ({ open, setOpen }) => {
  const dispatch = useDispatch()
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography color="textPrimary" variant="h3">
          Change Password
        </Typography>
      </DialogTitle>
      <Formik
        initialValues={{
          password: '',
          newPassword: '',
          newPasswordConfirmation: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          console.log(`onSubmit: values`, values)
          dispatch(
            changePassword({
              ...values,
              done: () => {
                setOpen(false)
              },
            })
          )
          resetForm()
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <DialogContent>
              <Field
                name="password"
                type="password"
                label="Current Password"
                fullWidth
                component={TextField}
                // variant="outlined"
                margin="normal"
              />
              <Field
                name="newPassword"
                type="password"
                label="New Password"
                fullWidth
                component={TextField}
                // variant="outlined"
                margin="normal"
              />
              <Field
                name="newPasswordConfirmation"
                type="password"
                label="Re-enter New Password"
                fullWidth
                component={TextField}
                // variant="outlined"
                margin="normal"
              />
            </DialogContent>
            <Box mt={3}>
              <DialogActions>
                <Button
                  color="primary"
                  // disabled={isSubmitting}
                  // fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={
                    isSubmitting ||
                    values.password.trim() === '' ||
                    values.newPassword.trim() === '' ||
                    values.newPasswordConfirmation === '' ||
                    values.newPassword !== values.newPasswordConfirmation
                  }
                >
                  Submit
                </Button>
                <Button
                  color="secondary"
                  // disabled={isSubmitting}
                  // fullWidth
                  size="large"
                  variant="contained"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}
