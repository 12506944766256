import { Typography } from '@material-ui/core'
import React from 'react'

export const Logo = (props) => {
  return (
    // <img
    //   alt="Logo"
    //   // src={`${process.env.PUBLIC_URL}/static/logo.svg`}
    //   src={`${process.env.PUBLIC_URL}/static/images/LearnerNotes.png`}
    //   {...props}
    // />
    <Typography
      variant="h3"
      color="white"
      style={{
        fontFamily: 'lato',
        fontSize: '28px',
        letterSpacing: '-1.4px',
        fontWeight: 300,
        fontStyle: 'italic',
        marginLeft: '20px',
      }}
    >
      learners' notes
    </Typography>
  )
}
