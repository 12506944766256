export const defaultData = {
  time: Date.now(),
  blocks: [
    {
      type: 'header',
      data: {
        text: '',
        level: 1,
        _id: '2000',
      },
    },
    // {
    //   type: 'paragraph',
    //   data: {
    //     text: '',
    //   },
    // },
    // {
    //   type: 'image',
    //   data: {
    //     url:
    //       'https://cdn.pixabay.com/photo/2017/09/01/21/53/blue-2705642_1280.jpg',
    //     caption: 'Here is a caption field',
    //     withBorder: false,
    //     withBackground: false,
    //     stretched: true,
    //   },
    // },
  ],
}

export const defaultData1 = {
  time: Date.now(),
  blocks: [
    {
      type: 'header',
      data: {
        text: 'My Notes',
        level: 1,
        _id: '2000',
        schedule: Date.now(),
        history: [
          { date: Date.now(), score: 5.0 },
          { date: Date.now(), score: 4.0 },
        ],
      },
    },
    {
      type: 'paragraph',
      data: {
        text: 'This is a test',
        _id: '2001',
        schedule: Date.now(),
        history: [
          { date: Date.now(), score: 5.0 },
          { date: Date.now(), score: 4.0 },
        ],
      },
    },
    {
      type: 'paragraph',
      data: {
        text: 'How you doing',
        _id: '2002',
        schedule: Date.now(),
        history: [
          { date: Date.now(), score: 5.0 },
          { date: Date.now(), score: 4.0 },
        ],
      },
    },
    {
      type: 'embed',
      data: {
        service: 'youtube',
        source: 'https://youtu.be/eed6tEoqLg8',
        embed: 'https://www.youtube.com/embed/eed6tEoqLg8',
        width: 580,
        height: 320,
        _id: '2005',
        caption: 'You tube video test',
        schedule: Date.now(),
        history: [
          { date: Date.now(), score: 5.0 },
          { date: Date.now(), score: 4.0 },
        ],
      },
    },
    {
      type: 'image',
      data: {
        url:
          'https://upload.wikimedia.org/wikipedia/commons/5/5a/Tornado_view%2C_June_2%2C_1929%2C_Hardtner%2C_Kansas.jpg',
        caption: 'Image Test',
        withBorder: false,
        withBackground: false,
        stretched: false,
        _id: '2004',
        schedule: Date.now(),
        history: [
          { date: Date.now(), score: 5.0 },
          { date: Date.now(), score: 4.0 },
        ],
      },
    },
    {
      type: 'video',
      data: {
        url: 'https://mobiedge.ca/videos/video.mp4',
        caption: 'Mobiedge Technologies Inc.',
        _id: '2010',
        schedule: Date.now(),
        history: [
          { date: Date.now(), score: 5.0 },
          { date: Date.now(), score: 4.0 },
        ],
      },
    },
    {
      type: 'list',
      data: {
        style: 'ordered',
        items: ['List Item 1', 'List Item 2', 'List Item 5'],
        _id: '3010',
        schedule: Date.now(),
        history: [
          { date: Date.now(), score: 5.0 },
          { date: Date.now(), score: 4.0 },
        ],
      },
    },
    {
      type: 'code',
      data: {
        code: 'print(f"Hi world!")\nreturn()\n\ndone()\n',
        _id: '3011',
        schedule: Date.now(),
        history: [
          { date: Date.now(), score: 5.0 },
          { date: Date.now(), score: 4.0 },
        ],
      },
    },
  ],
  version: '2.20.2',
}

export const defaultData2 = {
  _id: '608975cd151b53308d9de2fb',
  ownerId: '6076a93d5f5f8320f4fd1206',
  blocks: [
    {
      type: 'paragraph',
      data: {
        history: [],
        _id: '608975cd151b53308d9de2fa',
        text: 'hi',
        ownerId: '6076a93d5f5f8320f4fd1206',
        schedule: null,
        createdAt: '2021-04-28T14:48:45.008Z',
        updatedAt: '2021-04-28T14:48:45.008Z',
        __v: 0,
      },
      history: [],
      _id: '608975cd151b53308d9de2fa',
      ownerId: '6076a93d5f5f8320f4fd1206',
      schedule: null,
      createdAt: '2021-04-28T14:48:45.008Z',
      updatedAt: '2021-04-28T14:48:45.008Z',
      __v: 0,
    },
    {
      type: 'paragraph',
      data: {
        history: [],
        _id: '608975cd151b53308d9de2fa',
        text: 'hiasdfasdf this is a test',
        ownerId: '6076a93d5f5f8320f4fd1206',
        schedule: null,
        createdAt: '2021-04-28T14:48:45.008Z',
        updatedAt: '2021-04-28T14:48:45.008Z',
        __v: 0,
      },
      history: [],
      _id: '608975cd151b53308d9de2fa',
      ownerId: '6076a93d5f5f8320f4fd1206',
      schedule: null,
      createdAt: '2021-04-28T14:48:45.008Z',
      updatedAt: '2021-04-28T14:48:45.008Z',
      __v: 0,
    },
  ],
}
// export const defaultData2 = {
//   _id: '608975cd151b53308d9de2fb',
//   ownerId: '6076a93d5f5f8320f4fd1206',
//   blocks: [
//     {
//       history: [],
//       _id: '608975cd151b53308d9de2fa',
//       type: 'paragraph',
//       data: {
//         text: 'hi',
//       },
//       ownerId: '6076a93d5f5f8320f4fd1206',
//       schedule: null,
//       createdAt: '2021-04-28T14:48:45.008Z',
//       updatedAt: '2021-04-28T14:48:45.008Z',
//       __v: 0,
//     },
//   ],
// }

export const defaultData3 = {
  _id: '608b32ea4447765c7011fdfd',
  ownerId: '6076a93d5f5f8320f4fd1206',
  blocks: [
    {
      _id: '608b32ea4447765c7011fdfc',
      type: 'paragraph',
      data: {
        text: 'asdfasdf',
        ownerId: '6076a93d5f5f8320f4fd1206',
        schedule: null,
        history: [],
      },
      createdAt: '2021-04-29T22:27:54.356Z',
      updatedAt: '2021-04-29T22:27:54.356Z',
      __v: 0,
    },
  ],
}
