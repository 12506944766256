export class SimpleVideo {
  constructor({ data, api, config }) {
    this.api = api
    this.config = config || {}
    this.data = {
      url: data.url || '',
      caption: data.caption || '',

      _id: data._id,
      history: data.history,
      schedule: data.schedule,
    }
    this.wrapper = undefined
  }

  static get toolbox() {
    return {
      title: 'Video',
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z"/></svg>',
    }
  }

  static get pasteConfig() {
    return {
      pattern: {
        video: /https?:\/\/\S+\.(mp4|mpe?g|avi|mkv|ogg|webm)$/i,
      },
    }
  }

  static get sanitize() {
    return {
      url: {},
      caption: {
        b: true,
        a: {
          href: true,
        },
        i: true,
      },
    }
  }

  render() {
    this.wrapper = document.createElement('div')
    this.wrapper.classList.add('simple-video')
    if (this.data && this.data.url) {
      this._createVideo(this.data.url, this.data.caption)
      return this.wrapper
    }
    const input = document.createElement('input')
    input.placeholder = this.config.placeholder || 'Paste a video URL...'
    input.addEventListener('paste', (e) => {
      this._createVideo(e.clipboardData.getData('text'))
    })
    this.wrapper.appendChild(input)
    return this.wrapper
  }

  _createVideo(url, captionText) {
    const video = document.createElement('video')
    video.width = 480
    video.controls = true
    const source = document.createElement('source')
    source.src = url
    video.appendChild(source)

    const caption = document.createElement('div')
    caption.contentEditable = true
    caption.innerHTML = captionText || ''

    this.wrapper.innerHTML = ''
    this.wrapper.appendChild(video)
    this.wrapper.appendChild(caption)
  }

  save(blockContent) {
    const video = blockContent.querySelector('video')
    const source = blockContent.querySelector('video source')
    const caption = blockContent.querySelector('[contenteditable]')
    return Object.assign(this.data, {
      url: source ? source.src || '' : '',
      caption: caption ? caption.innerHTML || '' : '',
    })
  }

  validate(savedData) {
    if (!savedData.url.trim()) return false
    return true
  }

  onPaste(e) {
    switch (e.type) {
      case 'pattern':
        const src = e.detail.data
        this._createVideo(src)
        break
      default:
        break
    }
  }
}
