import { useDispatch } from 'react-redux'
import { Navigate, useParams } from 'react-router-dom'
import { rootPath } from '../../../config'
import { showToast } from '../../utilities'
import { confirmEmail } from '../ducks'

export const EmailConfirmation = () => {
  const dispatch = useDispatch()
  const { token } = useParams()
  if (token) {
    dispatch(confirmEmail({ token }))
  } else {
    dispatch(
      showToast({ message: `Confirmation data error. Please contact support.` })
    )
  }
  return <Navigate to={`${rootPath}/login`} replace={true} />
}
