export const LOCAL_DEBUG = false

export const BASE_URL = LOCAL_DEBUG
  ? `http://localhost:3999`
  : // ? `http://134.122.35.174:3999`
    `https://notes.mobiedge.ca`

export const AUTH_ENDPOINT = `/api/auth`
export const NOTES_ENDPOINT = `/api/notes`
export const BLOCKS_ENDPOINT = `/api/blocks`

// export const rootPath = LOCAL_DEBUG ? '' : '/notes'
export const rootPath = ''

/////////////////////////////////////////////////////////////////////////////////////////////////
// in package.json

// for production:
// "homepage": "https://notes.mobiedge.ca"

// for local debug:
// "proxy": "http://localhost:3999"

/////////////////////////////////////////////////////////////////////////////////////////////////

// React app deployment step 1:  To test API on remote server using local CRA app, replace `http://localhost:3999` with `http://134.122.35.174:3999` in `src/config/serverConfig.js` and `package.json`

// React app deployment step 2: set the root path of the app on the server in `config.js`:
// export const LOCAL_DEBUG = false
//Make sure all routes and navigate targets are set accordingly.

// React app deployment step 3: update `package.json` and `serverConfig` again before building production version. "homepage": "https://www.mobiedge.ca/notes"

// React app deployment step 4: `yarn build` & `scp -r ./build  yu@134.122.35.174:/home/yu`, then rename and move `build` to `/var/www/html/notes`

/////////////////////////////////////////////
// Deployment:
// 1. In config.js: export const LOCAL_DEBUG = false
// 2. In package.json: "homepage": "https://www.mobiedge.ca/notes"
// 3. yarn build
// 4. scp -r ./build  yu@134.122.35.174:/home/yu
// 5. mv build /var/www/html
// 6. cd /var/www/html
// 7. mv notes notes.bkn
// 8. mv build notes
