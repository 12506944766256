import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchNotes, importNotes } from './ducks'

export const ImportDialog = ({ importOpen, setImportOpen }) => {
  const dispatch = useDispatch()
  const [fileContent, setFileContent] = useState([])
  useEffect(() => {
    console.log(`fileContent`, fileContent)
  }, [fileContent])
  const handleClose = () => {
    setImportOpen(false)
  }
  return (
    <Dialog open={importOpen} onClose={handleClose}>
      <DialogTitle>Import Notes</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the notes file that you exported previously and press Upload
        </DialogContentText>
        <Input
          type="file"
          accept=".json"
          onChange={(e) => {
            console.log(`file changed.`, e.target.files)
            const fileReader = new FileReader()
            fileReader.onloadend = (e) => {
              setFileContent(JSON.parse(fileReader.result))
            }
            fileReader.readAsText(e.target.files[0])
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={!Array.isArray(fileContent) || fileContent.length < 1}
          onClick={() => {
            dispatch(
              importNotes({
                notes: fileContent,
                done: () => {
                  handleClose()
                  setImmediate(() => {
                    dispatch(fetchNotes({}))
                  })
                },
              })
            )
          }}
        >
          Upload
        </Button>
        <Button color="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
