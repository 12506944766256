import { createSlice } from '@reduxjs/toolkit'

const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    on: false,
    message: '',
    severity: 'error',
    autoHideDuration: 5000,
  },
  reducers: {
    showToast: (state, action) => {
      state.on = true
      state.message = action.payload.message || ''
      state.severity = action.payload.severity || 'error'
      state.autoHideDuration = action.autoHideDuration || 5000
    },
    dismissToast: (state, action) => {
      state.on = false
    },
  },
})

export const toastReducer = toastSlice.reducer
export const { showToast, dismissToast } = toastSlice.actions
