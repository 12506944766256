import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useTheme,
} from '@material-ui/core'
import { TablePaginationActions } from './TablePaginationActions'
// import { usePagination } from '@material-ui/lab'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
  usePagination,
} from 'react-table'
import { TableHeaderBar } from './TableHeaderBar'

export const DataTable = (props) => {
  const {
    columns,
    data,
    tablePageSize,
    hookFunction,
    handleChangeRowsPerPage,
    handleSelectedFlatRows,
    handleDeleting,
    maxWidth,
    hasTableToolbar,
    selectedItemNames,
    titleText,
    subTitleText,
    addHandler,
    multiAddHandler,
    tableSize,
    isRowSelected,
    onRowDoubleClick,

    headerButtons,
    selectedHeaderButtons,
  } = props

  const dispatch = useDispatch()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,

    state: { globalFilter, pageIndex, pageSize },
    setGlobalFilter,

    page,
    gotoPage,
    setPageSize,

    selectedFlatRows,
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hookFunction
  )

  useEffect(() => {
    if (setPageSize) setPageSize(tablePageSize)
  }, [tablePageSize])

  useEffect(() => {
    handleSelectedFlatRows(selectedFlatRows)
  }, [selectedFlatRows])

  const handleChangePage = (e, newPage) => gotoPage(newPage)

  return (
    <Container maxWidth={maxWidth} style={{ height: '100%' }}>
      {hasTableToolbar && (
        <TableHeaderBar
          numSelected={selectedItemNames.length}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
          titleText={titleText}
          headerButtons={headerButtons}
          selectedHeaderButtons={selectedHeaderButtons}
          // subTitleText={subTitleText}
          // addHandler={addHandler}
          // multiAddHandler={multiAddHandler}
          // deleteHandler={() => {
          //   dispatch(
          //     setConfirmationParams({
          //       title: 'Confirm Deleting',
          //       content:
          //         'Selected item(s) will be deleted permanently, do you want to continue?\r\n\r\n',
          //       okCallback: () => {
          //         setImmediate(() => {
          //           handleDeleting()
          //         })
          //       },
          //     })
          //   )
          //   dispatch(showConfirmation())
          // }}
        />
      )}
      {data.length > 0 && (
        <Table {...getTableProps()} stickyHeader size={tableSize || 'small'}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    {column.id !== 'selection' ? (
                      <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              const isSelected = isRowSelected()
              return (
                <TableRow
                  {...row.getRowProps()}
                  onDoubleClick={() => onRowDoubleClick(row)}
                  selected={isSelected}
                >
                  {row.cells.map((cell) => (
                    <TableCell {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                count={rows.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                SelectProps={{
                  inputProps: { 'arial-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={(e) => {
                  const tablePageSize = parseInt(e.target.value, 10)
                  handleChangeRowsPerPage(tablePageSize)
                  gotoPage(0)
                }}
                ActionComponent={() => (
                  <TablePaginationActions
                    count={rows.length}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    onChangePage={handleChangePage}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </Container>
  )
}
