import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
  Tooltip,
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Logo } from '../../assets'
import { logoutUser } from '../auth'
import { Input, Menu } from '@material-ui/icons'

const useStyles = makeStyles({
  root: {
    minWidth: 320,
  },
  avatar: {
    width: 60,
    height: 60,
  },
  toolbar: {
    height: 64,
    paddingLeft: 8,
  },
})

export const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [notifications, setNotifications] = useState([])

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/" style={{ textDecoration: 'none', color: 'white' }}>
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden xsDown>
          <Tooltip title="Logout">
            <IconButton
              color="inherit"
              onClick={() => dispatch(logoutUser({}))}
            >
              <Input />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden smUp>
          <Tooltip title="Menu">
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <Menu />
            </IconButton>
          </Tooltip>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}
