import { useState } from 'react'
import { Outlet } from 'react-router'
import { useSharedStyles } from '../../styles'
import { NavBar } from './NavBar'
import { TopBar } from './TopBar'

export const UserLayout = () => {
  const classes = useSharedStyles()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => {
          setMobileNavOpen(true)
        }}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapperWithNav}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}
