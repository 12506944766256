import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { BASE_URL, BLOCKS_ENDPOINT, LOCAL_DEBUG } from '../../config'
import { reqBody, thunkFetch } from '../utilities'

const endpoint = LOCAL_DEBUG ? BLOCKS_ENDPOINT : `${BASE_URL}${BLOCKS_ENDPOINT}`

export const fetchBlocks = createAsyncThunk(
  'blocks/fetchBlocks',
  async ({ user, done }, thunkAPI) => {
    return thunkFetch({
      thunkAPI,
      message: 'Fetching tasks...',
      errMessage: 'Failed fetching tasks',
      url: endpoint,
      done,
    })
  }
)

export const fetchABlock = createAsyncThunk(
  'blocks/fetchABlock',
  async ({ id, done }, thunkAPI) => {
    return thunkFetch({
      thunkAPI,
      message: 'Fetching task...',
      errMessage: 'Failed fetching task',
      url: `${endpoint}/${id}`,
      done,
    })
  }
)

export const fetchActiveNotes = createAsyncThunk(
  'blocks/fetchActiveNotes',
  async ({ done }, thunkAPI) => {
    return thunkFetch({
      thunkAPI,
      message: 'Fetching task...',
      errMessage: 'Failed fetching task',
      url: `${endpoint}/activeNotes`,
      done,
    })
  }
)

export const fetchNewSchedule = createAsyncThunk(
  'blocks/fetchNewSchedule',
  async ({ id, evaluation, done }, thunkAPI) => {
    return thunkFetch({
      thunkAPI,
      message: 'Fetching schedule',
      errMessage: 'Failed fetching schedule',
      url: `${endpoint}/schedule`,
      body: reqBody('POST', { id, evaluation }),
      done,
    })
  }
)

export const activeTask = createAsyncThunk(
  'blocks/activeTask',
  async ({ id, active, done }, thunkAPI) => {
    return thunkFetch({
      thunkAPI,
      message: 'Active/Deactive task',
      errMessage: 'Failed activating/deactivating task',
      url: `${endpoint}/active`,
      body: reqBody('POST', { id, active }),
      done,
    })
  }
)

export const resetTask = createAsyncThunk(
  'blocks/resetTask',
  async ({ id, done }, thunkAPI) => {
    return thunkFetch({
      thunkAPI,
      message: 'Active/Deactive task',
      errMessage: 'Failed activating/deactivating task',
      url: `${endpoint}/reset`,
      body: reqBody('POST', { id }),
      done,
    })
  }
)

const blocksSlice = createSlice({
  name: 'blocks',
  initialState: {
    blocks: [],
    currentBlock: null,
    tablePageSize: 10,
    // noteBlocks: [],
    // notes: [],
    activeNotes: [],
  },
  reducers: {
    setTablePageSize: (state, action) => {
      state.tablePageSize = action.payload.tablePageSize
    },
  },
  extraReducers: {
    [fetchBlocks.fulfilled]: (state, action) => {
      state.blocks = action.payload
    },
    [fetchBlocks.rejected]: (state, action) => {
      console.log(`fetchBlocks rejected`, JSON.stringify(action, null, 2))
      state.blocks = []
    },
    [fetchABlock.fulfilled]: (state, action) => {
      state.currentBlock = action.payload
    },
    [fetchABlock.rejected]: (state, action) => {
      state.currentBlock = null
    },
    // [fetchNoteBlocks.fulfilled]: (state, action) => {
    //   state.noteBlocks = action.payload.blocks
    // },
    // [fetchNoteBlocks.rejected]: (state, action) => {
    //   state.noteBlocks = []
    // },
    // [fetchNewSchedule.fulfilled]: (state, action) => {},
    // [fetchNewSchedule.rejected]: (state, action) => {},
    // [activeTask.fulfilled]: (state, action) => {},
    // [activeTask.rejected]: (state, action) => {},
    // [fetchTaskNotes.fulfilled]: (state, action) => {
    //   state.notes = action.payload
    // },
    // [fetchTaskNotes.rejected]: (state, action) => {
    //   state.notes = []
    // },
    [fetchActiveNotes.fulfilled]: (state, action) => {
      state.activeNotes = action.payload
    },
    [fetchActiveNotes.rejected]: (state, action) => {
      state.activeNotes = []
    },
  },
})

export const blocksReducer = blocksSlice.reducer
export const { setTablePageSize } = blocksSlice.actions
