import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { AUTH_ENDPOINT, BASE_URL, LOCAL_DEBUG } from '../../config'
import { reqBody, showToast, thunkFetch } from '../utilities'

const endpoint = LOCAL_DEBUG ? AUTH_ENDPOINT : `${BASE_URL}${AUTH_ENDPOINT}`

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ user, done }, thunkAPI) =>
    thunkFetch({
      thunkAPI,
      message: `Logging in ...`,
      errMessage: `Failed logging in. Please check your email and password.`,
      url: `${endpoint}/login`,
      body: reqBody('POST', user),
      done,
    })
)

export const logoutUser = createAsyncThunk(
  'auth/logoutUser',
  async ({ user, done }, thunkAPI) =>
    thunkFetch({
      thunkAPI,
      message: `Logging out ...`,
      errMessage: `Failed logging out`,
      url: `${endpoint}/logout`,
      body: reqBody('POST'),
      done,
    })
)

export const signupUser = createAsyncThunk(
  'auth/signupUser',
  async ({ user, done }, thunkAPI) =>
    thunkFetch({
      thunkAPI,
      message: `Signing up ...`,
      errMessage: `Failed signing up. Please check your info and network connection.`,
      url: `${endpoint}/register`,
      body: reqBody('POST', user),
      done,
    })
)

export const confirmEmail = createAsyncThunk(
  'auth/confirmEmail',
  async ({ token, done }, thunkAPI) =>
    thunkFetch({
      thunkAPI,
      message: `Checking ...`,
      errMessage: `Failed confirming email address.`,
      url: `${endpoint}/confirmEmail/${token}`,
      done,
    })
)

export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async ({ user, done }, thunkAPI) =>
    thunkFetch({
      thunkAPI,
      message: `Updating user account ...`,
      errMessage: `Failed updating user information.`,
      url: endpoint,
      body: reqBody('PUT', user),
      done,
    })
)

export const deleteUser = createAsyncThunk(
  'auth/deleteUser',
  async ({ user, done }, thunkAPI) =>
    thunkFetch({
      thunkAPI,
      message: `Deleting account ...`,
      errMessage: `Failed deleting account.`,
      url: endpoint,
      body: reqBody('DELETE'),
      // body: reqBody('DELETE', user),
      done,
    })
)

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({ password, newPassword, done }, thunkAPI) =>
    thunkFetch({
      thunkAPI,
      message: `Updating password ...`,
      errMessage: `Failed updating password.`,
      url: `${endpoint}/password`,
      body: reqBody('POST', { password, newPassword }),
      done: (resBody) => {
        thunkAPI.dispatch(
          showToast({ message: 'Password updated', severity: 'info' })
        )
        done(resBody)
      },
    })
)

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ email, done }, thunkAPI) =>
    thunkFetch({
      thunkAPI,
      message: `Resetting password ...`,
      errMessage: `Failed resetting password.`,
      url: `${endpoint}/resetPassword`,
      body: reqBody('POST', { email }),
      done,
    })
)

export const passwordReset = createAsyncThunk(
  'auth/passwordReset',
  async ({ token, password, passwordConfirmation, done }, thunkAPI) =>
    thunkFetch({
      thunkAPI,
      message: `Updating password ...`,
      errMessage: `Failed updating password.`,
      url: `${endpoint}/passwordReset`,
      body: reqBody('POST', { token, password, passwordConfirmation }),
      done,
    })
)

export const fetchSession = createAsyncThunk(
  'auth/fetchSession',
  async ({ user, done }, thunkAPI) =>
    thunkFetch({
      thunkAPI,
      message: `Loading ...`,
      errMessage: `Connection error.`,
      url: `${endpoint}/session`,
      done,
    })
)

const fulfilledWithUser = (state, action) => {
  state.session.user = action.payload
}

const fulfilledWithoutUser = (state, action) => {
  state.session.user = {}
}

const rejectionHandler = (state, action) => {
  if (action.payload && action.payload._id) {
    state.session.user = action.paylod
  }
}

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    session: { user: {} },
  },
  reducers: {
    doneLogin: (state, action) => {
      state.session.user = action.payload.user
    },
    clearSessionOnError: (state, action) => {
      state.session.user = {}
    },
    doneLogoutOAuth: (state, action) => {
      state.session.user = {}
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: fulfilledWithUser,
    [loginUser.rejected]: rejectionHandler,

    [logoutUser.fulfilled]: fulfilledWithoutUser,
    [logoutUser.rejected]: rejectionHandler,

    [signupUser.fulfilled]: fulfilledWithUser,
    [signupUser.rejected]: rejectionHandler,

    [updateUser.fulfilled]: fulfilledWithUser,
    [updateUser.rejected]: rejectionHandler,

    [deleteUser.fulfilled]: fulfilledWithoutUser,
    [deleteUser.rejected]: rejectionHandler,

    [changePassword.fulfilled]: (state, action) => {},

    [confirmEmail.fulfilled]: (state, action) => {},

    [fetchSession.fulfilled]: fulfilledWithUser,
    [fetchSession.rejected]: rejectionHandler,
  },
})

export const authReducer = authSlice.reducer
export const { doneLogin, clearSessionOnError, doneLogoutOAuth } =
  authSlice.actions

export const isLoggedIn = createSelector(
  (state) => state.auth.session.user,
  (user) => !!user._id
)
