import { createSlice } from '@reduxjs/toolkit'

const progressSlice = createSlice({
  name: 'progress',
  initialState: {
    messages: {},
    size: '50px',
    color: 'primary',
    thickness: 1.8,
  },
  reducers: {
    showProgress: (state, action) => {
      state.messages[action.payload.id] = action.payload.message
      state.size = action.payload.size || '50px'
      state.color = action.payload.color || 'primary'
      state.thickness = action.payload.thickness || 1.8
    },
    dismissProgress: (state, action) => {
      delete state.messages[action.payload.id]
    },
  },
})

export const progressReducer = progressSlice.reducer
export const { showProgress, dismissProgress } = progressSlice.actions
