import {
  Avatar,
  Box,
  Button,
  Container,
  useTheme,
  Typography,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Page, useBasicViewStyles, useSharedStyles } from '../../../styles'
import {
  AvatarDialog,
  setConfirmationParams,
  showConfirmation,
} from '../../utilities'
import { deleteUser, fetchSession, updateUser } from '../ducks'
import { PasswordDialog } from './PasswordDialog'

export const ProfileView = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useSharedStyles()
  const user = useSelector((state) => state.auth.session.user)
  const [avatarEditorOpen, setAvatarEditorOpen] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)

  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false)

  useEffect(() => {
    // console.log(`user.avatar`, user.avatar)
    setImageUrl(user.avatar)
  }, [user.avatar])

  // useEffect(() => {
  //   console.log(`imageUrl`, imageUrl)
  // }, [imageUrl])

  return (
    <Page className={classes.formpage} title="Bots">
      <Container
        maxWidth="lg"
        style={{ height: '100%', paddingLeft: theme.spacing(4) }}
      >
        <Box mt={3} mb={3}>
          <Typography
            color="textPrimary"
            variant="h3"
            style={{ marginRight: '25px' }}
          >
            Settings
          </Typography>
        </Box>
        <Box mb={6}>
          <Typography color="textPrimary" variant="h5">
            {`Account: ${user.email}`}
          </Typography>
        </Box>
        <Box mt={3} width="150px" textAlign="center">
          <Avatar
            style={{
              cursor: 'pointer',
              width: 150,
              height: 150,
            }}
            src={imageUrl}
            // src={
            //   user.avatar ||
            //   `${process.env.PUBLIC_URL}/static/images/avatars/avatar_4.png`
            // }
            onClick={() => {
              setImageUrl(null)
              setImmediate(() => {
                setImageUrl(imageUrl)
                setAvatarEditorOpen(true)
              })
            }}
          />
          <Typography color="textSecondary" variant="subtitle2">
            Tap to Edit
          </Typography>
        </Box>
        <Box mt={6} width="320px">
          <Button
            fullWidth
            color="primary"
            variant="contained"
            style={{ marginTop: theme.spacing(2) }}
            onClick={(e) => {
              setPasswordDialogOpen(true)
            }}
          >
            Change Password
          </Button>
        </Box>
        <Box mt={3} width="320px">
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            style={{ marginTop: theme.spacing(2) }}
            onClick={(e) => {
              dispatch(
                setConfirmationParams({
                  title: 'Delete Account',
                  content:
                    'This account is about to be deleted permenantly. Continue?',
                  // children: null,
                  okCallback: () => {
                    setImmediate(() => {
                      dispatch(deleteUser(user))
                    })
                  },
                })
              )
              dispatch(showConfirmation())
            }}
          >
            Delete Account
          </Button>
        </Box>
      </Container>
      <AvatarDialog
        imageUrl={imageUrl}
        open={avatarEditorOpen}
        setOpen={setAvatarEditorOpen}
        onOK={async (url) => {
          console.log(`****** onOK url`, url)
          await dispatch(
            updateUser({
              user: { avatar: url },
            })
          )
        }}
      />
      <PasswordDialog
        open={passwordDialogOpen}
        setOpen={setPasswordDialogOpen}
      />
    </Page>
  )
}
