import { Box, Button, Container, Typography } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import * as Yup from 'yup'
import { rootPath } from '../../../config'
import { FormPage } from '../../../styles'
// import { FormPage } from '../../../styles/Page'
import { showToast } from '../../utilities'
import { passwordReset, resetPassword } from '../ducks'

const validationSchema = Yup.object({
  // password: Yup.string().min(8).max(128).required(),
  password: Yup.string().min(8).max(128).required(),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password must match')
    .required(),
})

export const PasswordResetView = () => {
  const dispatch = useDispatch()
  const { token } = useParams()
  const navigate = useNavigate()

  return (
    <FormPage title="Reset Password">
      <Container maxWidth="sm">
        <Formik
          initialValues={{
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) =>
            dispatch(
              passwordReset({
                token,
                done: () => {
                  dispatch(
                    showToast({
                      message: 'Done. Please use new password to log in.',
                      severity: 'info',
                    })
                  )
                  navigate(`${rootPath}/login`)
                },
                ...values,
              })
            )
          }
        >
          <Form>
            <Box mb={3}>
              <Typography color="textPrimary" variant="h2">
                New Password
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Please enter your new password
              </Typography>
            </Box>
            <Field
              name="password"
              type="password"
              label="New Password"
              fullWidth
              component={TextField}
              variant="outlined"
              margin="normal"
            />
            <Field
              name="passwordConfirmation"
              type="password"
              label="Re-enter New Password"
              fullWidth
              component={TextField}
              variant="outlined"
              margin="normal"
            />
            <Box my={2}>
              <Button
                color="primary"
                // disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </Form>
        </Formik>
      </Container>
    </FormPage>
  )
}
