import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: false,
  params: {
    title: '',
    content: '',
    okTitle: 'OK',
    okCallback: null,
    cancelTitle: 'Cancel',
    cancelCallback: null,
    children: null,
  },
}

const confirmationSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    setConfirmationParams: (state, action) => {
      state.params = { ...initialState.params, ...action.payload }
    },
    showConfirmation: (state, action) => {
      state.open = true
    },
    dismissConfirmation: (state, action) => {
      state.open = false

      switch (action.payload.result) {
        case 'ok':
          if (state.params.okCallback) {
            state.params.okCallback(action.payload.arg)
          }
          break
        case 'cancel':
          if (state.params.cancelCallback) {
            state.params.cancelCallback(action.payload.arg)
          }
          break
        default:
          throw new Error(`Unkown result: ${action.payload.result}`)
      }
    },
  },
})

export const confirmationReducer = confirmationSlice.reducer
export const { setConfirmationParams, showConfirmation, dismissConfirmation } =
  confirmationSlice.actions
