import { Box, Container, Link, Typography, useTheme } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { Page, useSharedStyles } from '../../../styles'
// import { GlobalStyles, Page, useBasicViewStyles } from '../../../styles'

export const DashboardView = () => {
  const classes = useSharedStyles()
  const theme = useTheme()
  return (
    <Page className={classes.formpage} title="Start">
      <Container
        maxWidth="lg"
        style={{ display: 'flex', height: '100%', width: '100%' }}
      >
        <Box
          display="flex"
          // flex="1 1 auto"
          flexDirection="column"
          height="100%"
          width="100%"
          justifyContent="space-between"
          padding={'50px'}
        >
          <Box>
            <Box mb={2}>
              <Typography
                align="left"
                color="textPrimary"
                style={theme.typography.hugeTitle}
              >
                Start Here
              </Typography>
            </Box>
            <Typography
              align="left"
              color="textPrimary"
              style={theme.typography.cover}
            >
              Learners' Note leverages "Spaced Repetition" to help you remember
              anything you learned
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              style={theme.typography.cover}
            >
              - Take notes
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              style={theme.typography.cover}
            >
              - Finish due tasks
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              style={theme.typography.cover}
            >
              - Rinse and repeat
            </Typography>
            <Box mt={5}>
              <Link component={RouterLink} to="notes" variant="h4">
                Let's get started
              </Link>
            </Box>
          </Box>
          <Box flexGrow={1} />
          {/* <Box textAlign="right">
            <img
              alt="Info"
              style={{
                marginTop: 50,
                display: 'inline-block',
                maxWidth: '100%',
                width: '66%',
                opacity: '0.5',
              }}
              src="/static/images/undraw_Firmware_jw6u.svg"
            />
          </Box> */}
          <img
            style={{
              width: '45%',
              height: '45%',
              position: 'absolute',
              left: '50%',
              top: '50%',
              opacity: 0.4,
              // zIndex: -1,
            }}
            src={`${process.env.PUBLIC_URL}/static/images/undraw_Notes_re_pxhw.svg`}
            // src="/static/images/undraw_Firmware_jw6u.svg"
          />
        </Box>

        {/* <Box mb={3}>
          <Typography color="textPrimary" variant="h2">
            Start
          </Typography>
          <Typography color="textPrimary" gutterBottom variant="body2">
            Create your bots.
          </Typography>
        </Box> */}
      </Container>
    </Page>
  )
}
