import { Box, Button, Container, Typography } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { rootPath } from '../../../config'
import { FormPage } from '../../../styles'
import { resetPassword } from '../ducks'

const validationSchema = Yup.object({
  email: Yup.string().email().max(254).required(),
})

export const ResetView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  return (
    <FormPage title="Password">
      <Container maxWidth="sm">
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={(values) =>
            dispatch(
              resetPassword({
                email: values.email,
                done: () => navigate(`${rootPath}/resetPasswordDone`),
              })
            )
          }
        >
          <Form>
            <Box mb={3}>
              <Typography color="textPrimary" variant="h2">
                Reset Password
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Reset you current password
              </Typography>
            </Box>
            <Field
              name="email"
              type="email"
              label="Email Address"
              fullWidth
              variant="outlined"
              component={TextField}
              margin="normal"
            />
            <Box my={2}>
              <Button
                color="primary"
                // disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </Form>
        </Formik>
      </Container>
    </FormPage>
  )
}
