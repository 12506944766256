import { makeStyles } from '@material-ui/core'

export const useSharedStyles = makeStyles((theme) => ({
  formpage: {
    backgroundColor: theme.palette.background,
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  root: {
    backgroundColor: theme.palette.background,
    display: 'flex',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
  },
  wrapperWithNav: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    // backgroundImage: `${process.env.PUBLIC_URL}/static/images/undraw_Content_re_33px.png`,
    // backgroundImage: `${process.env.PUBLIC_URL}/static/images/undraw_Firmware_jw6u.svg`,
  },
  centered: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
  taskContainer: {
    display: 'flex',
    flexDirection: 'column',
    // flex: '1 1 auto',
    overflow: 'hidden',
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
  },
}))
