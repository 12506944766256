import { Box, Container, makeStyles, Typography } from '@material-ui/core'
import { Page } from '../../styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  },
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: '50%',
    opacity: '0.5',
    marginBottom: 100,
  },
}))

export const InfoView = ({ title, body, imagePath }) => {
  const classes = useStyles()
  return (
    <Page title="Info" className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          justifyContent="space-between"
          padding="100px"
        >
          <Box>
            <Typography align="left" color="textPrimary" variant="h2">
              {title}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              variant="subtitle1"
              style={{ marginTop: '50px' }}
            >
              {body}
            </Typography>
          </Box>
          <Box flexGrow={1} />
          <Box textAlign="right">
            <img alt="Info" className={classes.image} src={imagePath} />
          </Box>
        </Box>
      </Container>
    </Page>
  )
}
