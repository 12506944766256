import { v4 } from 'uuid'
import { clearSessionOnError } from '../auth'
import { dismissProgress, showProgress } from './progress'
import { showToast } from './toast'

export const errorHandler = (err, id, dispatch, rejectWithValue) => {
  dispatch(dismissProgress({ id }))
  dispatch(
    showToast({
      message: err.title || err.message || `Network error`,
    })
  )
  console.log(`err`, err)
  // this usually happens when user left the page for a substancial period of time and when he/she continue operations on the page but the session is already expired. We need to clear the session here which will redirect the page to login UI.
  if (err.status && err.status === 401) dispatch(clearSessionOnError())
  return rejectWithValue(err)
}

export const reqBody = (method, payload) =>
  payload
    ? {
        method,
        body: JSON.stringify(payload, null, 2),
        headers: { 'Content-Type': 'application/json' },
        // credentials: 'include',
      }
    : {
        method,
        // credentials: 'include',
      }

export const thunkFetch = async ({
  thunkAPI,
  message,
  errMessage,
  url,
  body,
  done,
}) => {
  const { dispatch, rejectWithValue } = thunkAPI
  const id = v4()
  try {
    if (message) dispatch(showProgress({ id, message }))
    const res = await fetch(url, body)
    if (message) dispatch(dismissProgress({ id }))
    let resBody
    const contentType = res.headers.get('content-type')
    if (contentType && contentType.indexOf('application/json') !== -1)
      resBody = await res.json()
    else {
      const text = await res.text()
      resBody = { message: text }
    }
    // console.log(`resBody`, resBody)
    // const newBody = JSON.parse(JSON.stringify(resBody))
    if (res.ok) {
      if (done) done(resBody)
      return resBody
    }
    throw resBody || new Error(`${res.status} ${errMessage}`)
  } catch (err) {
    return errorHandler(err, id, dispatch, rejectWithValue)
  }
}
