import {
  CircularProgress,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    left: 0,
    top: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: '#ffffffd0',
    zIndex: theme.zIndex.modal + 1,
  },
  message: {
    padding: '30px',
    opacity: 0.7,
  },
}))

export const Progress = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { messages, color, size, thickness } = useSelector(
    (state) => state.progress
  )
  const keys = Object.keys(messages)
  const len = keys.length

  // Show when there are one or more messages. Put the last (the latest one) message on display
  return len > 0 ? (
    <div className={classes.container}>
      <CircularProgress size={size} color={color} thickness={thickness} />
      <Typography className={classes.message} variant="subtitle1">
        {messages[keys[len - 1]]}
      </Typography>
    </div>
  ) : null
}

export * from './ducks'
