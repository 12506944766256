import {
  Button,
  Grid,
  Typography,
  useTheme,
  Link,
  Container,
  Box,
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import io from 'socket.io-client'
import { AUTH_ENDPOINT, BASE_URL } from '../../../config'
import { doneLogin, loginUser } from '../ducks'
import { FormPage } from '../../../styles'
import { Field, Form, Formik } from 'formik'
import { FacebookIcon, GoogleIcon } from '../../../assets'
import { TextField } from 'formik-material-ui'

const SocialButton = ({ icon, title, onClick }) => (
  <Button
    color="primary"
    fullWidth
    startIcon={icon}
    onClick={onClick}
    variant="contained"
  >
    {title}
  </Button>
)

const validationSchema = Yup.object({
  email: Yup.string().email().max(254).required(),
  password: Yup.string().max(254).required(),
})

export const LoginView = () => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const socketRef = useRef()
  const popupRef = useRef()

  useEffect(() => {
    socketRef.current = io(BASE_URL)
    return () => {
      socketRef.current.close()
      socketRef.current = null
    }
  }, [])

  const openPopup = (provider) => {
    const width = 600
    const height = 600
    const left = window.innerWidth / 2 - width / 2
    const top = window.innerHeight / 2 - height / 2
    const url = `${BASE_URL}${AUTH_ENDPOINT}/${provider}?socketId=${socketRef.current.id}`
    return window.open(
      url,
      '',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    )
  }

  const startAuth = (provider) => {
    socketRef.current.on(provider, (user) => {
      popupRef.current.close()
      socketRef.current.removeAllListeners()
      // setTimeout(() => {
      socketRef.current.close()
      dispatch(doneLogin({ user }))
      // }, 300)
    })
    popupRef.current = openPopup(provider)
  }

  return (
    <FormPage title="Login">
      <Container maxWidth="sm">
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={validationSchema}
          onSubmit={(values) => dispatch(loginUser({ user: values }))}
        >
          <Form>
            {/* <Box mb={3} alignSelf="center">
              <Typography
                color="textPrimary"
                variant="h1"
                style={{
                  fontFamily: 'lato',
                  fontWeight: '300',
                  fontSize: 64,
                  alignSelf: 'center',
                  textAlign: 'center',
                }}
              >
                learners' note
              </Typography>
            </Box> */}
            <Box mb={6}>
              <Typography color="textPrimary" variant="h2">
                Learners' Notes
              </Typography>
              <Typography color="textPrimary" variant="body1">
                A Mind-Extension Project
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <SocialButton
                  onClick={() => startAuth('facebook')}
                  icon={<FacebookIcon />}
                  title="Login with Facebook"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SocialButton
                  onClick={() => startAuth('google')}
                  icon={<GoogleIcon />}
                  title="Login with Google"
                />
              </Grid>
            </Grid>
            <Box mt={3} mb={1}>
              <Typography align="center" color="textSecondary" variant="body1">
                or login with an email address
              </Typography>
            </Box>

            <Field
              name="email"
              type="email"
              label="Email"
              fullWidth
              variant="outlined"
              component={TextField}
              margin="normal"
            />
            <Field
              name="password"
              type="password"
              label="Password"
              fullWidth
              variant="outlined"
              component={TextField}
              margin="normal"
            />
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign In
              </Button>
            </Box>
            <Typography
              color="textSecondary"
              variant="body1"
              align="center"
              style={{ marginTop: theme.spacing(5) }}
            >
              Don't have an account?{' '}
              <Link component={RouterLink} to="../register" variant="h6">
                Sign Up
              </Link>
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
              align="center"
              style={{ marginTop: theme.spacing(2) }}
            >
              Forgot your password?{' '}
              <Link component={RouterLink} to="/resetPassword" variant="h6">
                Reset Password
              </Link>
            </Typography>
          </Form>
        </Formik>
      </Container>
    </FormPage>
  )
}
