import { Badge, Container } from '@material-ui/core'
import { PlayCircleOutline } from '@material-ui/icons'
import { format } from 'date-fns/esm'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Page, useSharedStyles } from '../../styles'
import { InfoView } from '../utilities'
import { DataTable, TableCheckbox } from '../utilities/table'
import { fetchActiveNotes, fetchNewSchedule, setTablePageSize } from './ducks'
import { TasksDialog } from './TasksDialog'
// import { TaskTable } from './TaskTable'

const COLUMNS = [
  {
    Header: 'Title',
    accessor: 'title',
    Cell: ({ value }) => value || '[No Title]',
  },
  {
    Header: 'Last Modified',
    accessor: 'updatedAt',
    Cell: ({ value }) =>
      value
        ? format(new Date(value), 'MM/dd/yyyy')
        : format(new Date(), 'MM/dd/yyyy'),
    disableFilters: true,
  },
]

export const NoteTaskPage = () => {
  const classes = useSharedStyles()
  const dispatch = useDispatch()
  const data = useSelector((state) => state.blocks.activeNotes)
  const [taskIds, setTaskIds] = useState([])
  const [selectedNotes, setSelectedNotes] = useState([])
  const [tasksDialogOpen, setTasksDialogOpen] = useState(false)

  // useEffect(() => {
  //   dispatch(fetchActiveNotes({}))
  // }, [])

  // Update all notes on mount and each time the tasks dialog closes
  useEffect(() => {
    if (!tasksDialogOpen) {
      dispatch(fetchActiveNotes({}))
    }
  }, [tasksDialogOpen])

  // useEffect(() => {
  //   console.log(`taskIds`, taskIds)
  // }, [taskIds])

  const columns = useMemo(() => COLUMNS, [])
  const tablePageSize = useSelector((state) => state.notes.tablePageSize)

  const hookFunction = (hooks) => {
    hooks.allColumns.push((columns) => [
      ...columns,
      {
        id: 'taskIds',
        Header: 'Tasks',
        Cell: ({ row }) => {
          const taskCount = row.original.tasks.length
          return <Badge badgeContent={taskCount} color="secondary" />
        },
      },
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <TableCheckbox {...getToggleAllRowsSelectedProps()} />
        ),
        Cell: ({ row }) => (
          <TableCheckbox {...row.getToggleRowSelectedProps()} />
        ),
      },
    ])
  }

  const handleSelectedFlatRows = (selectedFlatRows) => {
    const noteIds = selectedFlatRows.map((row) => row.original._id)
    setSelectedNotes(noteIds)

    const selectedTasks = []
    selectedFlatRows.forEach((row) => {
      selectedTasks.push(...row.original.tasks)
    })
    setTaskIds(selectedTasks)
  }

  const handleChangeRowsPerPage = (tablePageSize) => {
    dispatch(setTablePageSize({ tablePageSize }))
  }

  const handleDeleting = () => {
    console.log(`current selected taskIds`, taskIds)
    setTasksDialogOpen(true)
  }

  const handleEvaluation = (id, evaluation) => {
    dispatch(
      fetchNewSchedule({
        id,
        evaluation,
        done: (resBody) => {
          console.log(`fetchNewSchedule resBody`, resBody)
          console.log(`taskIds`, taskIds)
          const newTaskIds = taskIds.slice(1)
          console.log(`newTaskIds`, newTaskIds)
          if (resBody.schedule <= Date.now()) {
            newTaskIds.push(resBody.id)
          }
          setTaskIds(newTaskIds)
          if (newTaskIds.length < 1) {
            setTasksDialogOpen(false)
          }
        },
      })
    )
  }

  const handleDeactivate = (id) => {
    console.log(`handleDeactivate in TaskPage`, id)
    const newTaskIds = taskIds.slice(1)
    setTaskIds(newTaskIds)
    if (newTaskIds.length < 1) {
      setTasksDialogOpen(false)
    }
  }

  const handleReset = (id) => {
    console.log(`handleReset in TaskPage`, id)
    const newTaskIds = taskIds.slice(1)
    newTaskIds.push(id)
    setTaskIds(newTaskIds)
  }

  const headerButtons = []
  const selectedHeaderButtons = [
    {
      tooltip: 'Start Selected Tasks oh oh',
      onClick: () => {
        console.log('oho ohohoh')
        setTasksDialogOpen(true)
      },
      icon: <PlayCircleOutline />,
    },
  ]

  console.log(`data`, data)
  return (
    <Page className={classes.formpage} title="Tasks">
      <Container maxWidth="lg">
        {data && data.length > 0 ? (
          <DataTable
            columns={columns}
            data={data}
            tablePageSize={tablePageSize}
            hookFunction={hookFunction}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleSelectedFlatRows={handleSelectedFlatRows}
            // handleDeleting={handleDeleting}
            maxWidth={false}
            hasTableToolbar={true}
            selectedItemNames={selectedNotes}
            titleText="Tasks"
            subTitleText="All due tasks. Build your future brick by brick..."
            // addHandler={null}
            // multiAddHandler={() => {}}
            tableSize="small"
            isRowSelected={() => false}
            onRowDoubleClick={(row) => {
              console.log(`open tasks: ${row.original._id}`)
              setTaskIds(row.original.tasks)
              setTasksDialogOpen(true)
            }}
            headerButtons={headerButtons}
            selectedHeaderButtons={selectedHeaderButtons}
          />
        ) : (
          <InfoView
            title="Well Done"
            body="You don't have and pending tasks for now. Relax and enjoy!"
            imagePath={`${process.env.PUBLIC_URL}/static/images/undraw_well_done_i2wr.svg`}
            // imagePath="/static/images/undraw_Newsletter_re_wrob.svg"
          />
        )}
      </Container>
      {tasksDialogOpen && (
        <TasksDialog
          taskIds={taskIds}
          tasksDialogOpen={tasksDialogOpen}
          setTasksDialogOpen={setTasksDialogOpen}
          handleEvaluation={handleEvaluation}
          handleDeactivate={handleDeactivate}
          handleReset={handleReset}
        />
      )}
    </Page>
  )
}
