import { Outlet } from 'react-router-dom'
import { useSharedStyles } from '../../styles'

export const GuestLayout = () => {
  const classes = useSharedStyles()
  return (
    <div className={classes.root}>
      <div className={classes.centered}>
        <Outlet />
      </div>
    </div>
  )
}
