import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

export const FileUploadDialog = ({ uploadDialogOpen, setUploadDialogOpen }) => {
  const handleClose = () => {
    setUploadDialogOpen(false)
  }
  return (
    <Dialog open={uploadDialogOpen} onClose={handleClose}>
      <DialogTitle>Import Notes</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the notes file that you exported previously and press Upload
        </DialogContentText>
        <form
          action="http://localhost:3999/api/notes/import"
          method="post"
          enctype="multipart/form-data"
        >
          <input type="file" name="notes" />
          <Button variant="contained" type="submit">
            Upload
          </Button>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
