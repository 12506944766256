import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Slide,
  TextField,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { Close, Done } from '@material-ui/icons'
import { forwardRef, useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Editor, setConfirmationParams, showConfirmation } from '../utilities'
import { createNote, updateNote } from './ducks'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const useStyles = makeStyles({
  root: {
    background: 'black',
  },
  input: {
    color: 'white',
    fontSize: 22,
    borderColor: 'white !important',
  },
})

export const NotesEditorDialog = ({
  editorOpen,
  setEditorOpen,
  initData,
  onChange,
}) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const classes = useStyles()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const [data, setData] = useState(initData)
  const [modified, setModified] = useState(false)
  const [title, setTitle] = useState('')

  useEffect(() => {
    // setData(initData)
    setTitle(initData ? initData.title || '' : '')
  }, [initData])

  useEffect(() => {
    console.log(`data changed`, data)
    console.log(`modified`, modified)
    // setModified(true)
  }, [data])

  useInterval(() => {
    console.log(`AutoSave: time's up, modified: ${modified}`)
    if (modified) saveData(false)
  }, 2000)

  // const saveWhenModified = () => {
  //   console.log(`AutoSave: time's up, modified: ${modified}`)
  //   saveData()
  // }

  // useEffect(() => {
  //   const autoSaveTimer = setInterval(saveWhenModified, 2000)
  //   return () => {
  //     clearInterval(autoSaveTimer)
  //   }
  // }, [])

  const saveData = (closeEditor) => {
    // if (!modified) return
    const finalData = {
      time: (data && data.time) || Date.now(),
      version: (data && data.version) || '2.20.2',
      blocks: (data && data.blocks) || [],
      title: (data && data.title) || '',
      active: (data && data.active) || true,
    }
    for (let i = 0; i < finalData.blocks.length; i++) {
      if (!finalData.blocks[i].data.schedule)
        finalData.blocks[i].data.schedule = Date.now()
      if (!finalData.blocks[i].data.stage) finalData.blocks[i].data.stage = 0
      // ATTENTION: `typeof` returns a string value
      if (typeof finalData.blocks[i].data.active === 'undefined')
        finalData.blocks[i].data.active = true
    }
    if (initData) {
      dispatch(
        updateNote({
          id: initData._id,
          note: { ...finalData, title },
          done: () => {
            setModified(false)
            if (closeEditor) {
              setEditorOpen(false)
            }
          },
        })
      )
    } else {
      dispatch(
        createNote({
          note: { ...finalData, title },
          done: () => {
            setModified(false)
            // setEditorOpen(false)
          },
        })
      )
    }
  }

  // const handleClose = () => {
  //   if (modified) {
  //     dispatch(
  //       setConfirmationParams({
  //         title: 'Waring: Data Changed',
  //         content:
  //           'You have modifications that are not saved yet. Do you want to discard all the changes and close the editor anyway?\r\n\r\n',
  //         okTitle: 'Discard and Close',
  //         okCallback: () => {
  //           setEditorOpen(false)
  //         },
  //       })
  //     )
  //     dispatch(showConfirmation())
  //   } else {
  //     setEditorOpen(false)
  //   }
  // }

  return (
    <Dialog
      fullScreen
      // fullScreen={fullScreen}
      // maxWidth="lg"
      // fullWidth
      open={editorOpen}
      onClose={() => {
        if (modified) saveData(true)
        else setEditorOpen(false)
      }}
      // onClose={handleClose}
      // onClose={() => setEditorOpen(false)}
      TransitionComponent={Transition}
    >
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          {/* <Typography variant="h3" style={{ flex: 1 }}>
            Editor
          </Typography> */}
          <TextField
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
              setModified(true)
            }}
            placeholder="Enter a Title..."
            id="title"
            lable="Title"
            InputProps={{ className: classes.input }}
          />
          <Box style={{ flex: 1 }} />
          {/* <Button
            autoFocus
            color="inherit"
            onClick={() => {
              console.log(`saved data`, data)
              saveData()
              // setModified(false)
              // setEditorOpen(false)
            }}
          >
            Save
          </Button> */}
          <IconButton
            autoFocus
            color="inherit"
            onClick={() => {
              console.log(`saved data`, data)
              if (modified) saveData(true)
              else setEditorOpen(false)
            }}
          >
            {/* <Done /> */}
            <Close />
          </IconButton>
          {/* <IconButton color="inherit" onClick={handleClose}>
            <Close />
          </IconButton> */}
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Editor
          // onEditorReady={onEditorReady}
          initData={initData}
          onDataChange={(content) => {
            setData(content)
            setModified(true)
          }}
        />
      </DialogContent>
    </Dialog>
  )
}
