import { InfoView } from '../../utilities'

export const ResetPasswordDoneView = () => {
  return (
    <InfoView
      title="Password Reset"
      body="We've sent an email to you. Please follow the link in the email to set your new password"
      imagePath={`${process.env.PUBLIC_URL}/static/images/undraw_Newsletter_re_wrob.svg`}
      // imagePath="/static/images/undraw_Newsletter_re_wrob.svg"
    />
  )
}
