import { InfoView } from '../../utilities'

export const RegisterDoneView = () => {
  return (
    <InfoView
      title="Welcome!"
      body="We've sent an email to you. Please follow the link in the email to finish signing up."
      imagePath={`${process.env.PUBLIC_URL}/static/images/undraw_Newsletter_re_wrob.svg`}
    />
  )
}
