import {
  Box,
  Button,
  Drawer,
  Hidden,
  List,
  makeStyles,
} from '@material-ui/core'
import {
  AssignmentTurnedIn,
  Check,
  Home,
  Note,
  Notes,
  Settings,
} from '@material-ui/icons'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { rootPath } from '../../config'
import { logoutUser } from '../auth'
import { NavItem } from './NavItem'

const items = [
  {
    href: `${rootPath}/home`,
    icon: Home,
    title: 'Start',
  },
  {
    href: `${rootPath}/home/notes`,
    icon: Notes,
    title: 'Notes',
  },
  {
    href: `${rootPath}/home/tasks`,
    icon: AssignmentTurnedIn,
    title: 'Tasks',
  },

  {
    href: `${rootPath}/home/settings`,
    icon: Settings,
    title: 'Settings',
  },
  // {
  //   href: '/app/help',
  //   icon: SettingsIcon,
  //   title: 'Help',
  // },
]
const useStyles = makeStyles({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
})

export const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const location = useLocation()
  const user = useSelector((state) => state.auth.session.user)

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
        <Hidden smUp>
          <Button onClick={() => dispatch(logoutUser({}))}>Log Out</Button>
        </Hidden>
      </Box>
      <Box flexGrow={1} />
    </Box>
  )

  return (
    <>
      <Hidden smUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  )
}
