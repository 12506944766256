import { createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => {
  // ATTENTION: forgotten 'return' leading to all created styles were missed. Need to review "Styles" part of Material-UI
  return createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        height: '100%',
        width: '100%',
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      root: {
        height: '100%',
        width: '100%',
      },
      a: {
        textDecoration: 'none',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
    },
  })
})

export const GlobalStyles = () => {
  useStyles()
  return null
}
