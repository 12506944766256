import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Slider,
} from '@material-ui/core'
import DropZone from 'react-dropzone'
import ReactAvatarEditor from 'react-avatar-editor'
import { useEffect, useState } from 'react'
import {
  Delete,
  PhotoCamera,
  RotateLeft,
  RotateRight,
  ZoomIn,
  ZoomOut,
} from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import { setConfirmationParams, showConfirmation } from './confirmation'

export const AvatarDialog = ({ imageUrl, open, setOpen, onOK }) => {
  const dispatch = useDispatch()
  const [modified, setModified] = useState(false)
  const [width, setWidth] = useState(250)
  const [height, setHeight] = useState(250)
  const [editor, setEditor] = useState(null)
  const [scale, setScale] = useState(1)
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 })
  const [rotate, setRotate] = useState(0)
  const [image, setImage] = useState(null)

  useEffect(() => {
    setImage(imageUrl)
    setScale(1)
    setPosition({ x: 0.5, y: 0.5 })
    setRotate(0)
  }, [imageUrl])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog open={open}>
      <DialogTitle id="form-dialog-title">Select Your Avatar</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To use your own image as your avatar, tap the camera icon or simply
          drop your file onto the image.
        </DialogContentText>

        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={2}
          style={{ marginTop: '50px', marginBotton: '50px' }}
        >
          <Grid item xs={12}>
            <Container
              style={{
                padding: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '250px',
                height: '250px',
              }}
            >
              <DropZone
                onDrop={(acceptedFiles) => {
                  console.log(`acceptedFiles`, acceptedFiles)
                  // acceptedFiles.forEach((file) => {
                  //   const reader = new FileReader()

                  //   reader.onabort = () =>
                  //     console.log('file reading was aborted')
                  //   reader.onerror = () =>
                  //     console.log('file reading has failed')
                  //   reader.onload = () => {
                  //     // Do whatever you want with the file contents
                  //     const binaryStr = reader.result
                  //     console.log(binaryStr)
                  //   }
                  //   reader.readAsArrayBuffer(file)
                  // })
                  setImage(acceptedFiles[0])
                  setModified(true)
                }}
                // disableClick
                // multiple={false}
                // style={{ width, height }}
              >
                {({ getRootProps, getInputProps }) => {
                  // console.log(`getRootProps`, getRootProps())
                  // console.log(`getInputProps`, getInputProps())
                  return (
                    <ReactAvatarEditor
                      ref={(editor) => setEditor(editor)}
                      scale={scale}
                      width={width}
                      height={height}
                      position={position}
                      onPositionChange={(position) => {
                        setPosition(position)
                        setModified(true)
                      }}
                      rotate={rotate}
                      borderRadius={width / 2}
                      image={image}
                      crossOrigin="anonymous"
                      className="editor-canvas"
                      border={0}
                    />
                  )
                }}
              </DropZone>
            </Container>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <input
              style={{ display: 'none' }}
              accept="image/*"
              type="file"
              id="icon-button-file"
              onChange={(e) => {
                if (e.target.files[0]) {
                  setImage(e.target.files[0])
                  setModified(true)
                }
              }}
            />
            <label htmlFor="icon-button-file">
              <IconButton
                color="primary"
                aria-label="Upload picture"
                component="span"
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <IconButton
              color="primary"
              disabled={!Boolean(image)}
              onClick={(e) => {
                e.preventDefault()
                setRotate(rotate - 90)
                setModified(true)
              }}
            >
              <RotateLeft />
            </IconButton>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <IconButton
              color="primary"
              disabled={!Boolean(image)}
              onClick={(e) => {
                e.preventDefault()
                setRotate(rotate + 90)
                setModified(true)
              }}
            >
              <RotateRight />
            </IconButton>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <IconButton
              color="secondary"
              disabled={!Boolean(image)}
              onClick={(e) => {
                setImage(null)
                setModified(true)
              }}
            >
              <Delete />
            </IconButton>
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={3} />
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item>
                <ZoomOut />
              </Grid>
              <Grid item xs>
                <Slider
                  min={1}
                  max={4}
                  step={0.01}
                  value={scale}
                  disabled={!Boolean(image)}
                  onChange={(e, newValue) => {
                    setScale(parseFloat(newValue))
                    setModified(true)
                  }}
                />
              </Grid>
              <Grid item>
                <ZoomIn />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (modified) {
              dispatch(
                setConfirmationParams({
                  title: 'Avatar Modified',
                  content:
                    'Some modifications have not been saved yet. Are you sure you want to discard them?',
                  okTitle: 'Go ahead and close',
                  cancelTitle: 'No, go back',
                  okCallback: () => {
                    setModified(false)
                    handleClose()
                  },
                })
              )
              dispatch(showConfirmation())
            } else {
              setModified(false)
              handleClose()
            }
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            // todo: dispatch user update
            onOK(
              image === null
                ? null
                : editor.getImageScaledToCanvas().toDataURL()
            )
            setModified(false)
            handleClose()
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
