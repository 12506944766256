import React from 'react'
import { Box, Container, Typography, makeStyles } from '@material-ui/core'
import { Page } from '../../../styles'
import { BorderStyleOutlined } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560,
  },
}))

export const InfoView = ({ title, body, imagePath }) => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Info">
      <Container
        maxWidth={'md'}
        style={{ display: 'flex', height: '100%', width: '100%' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          justifyContent="space-between"
          padding={'100px'}
        >
          <Box>
            <Typography align="left" color="textPrimary" variant="h1">
              {title}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              variant="subtitle1"
              style={{ marginTop: '50px' }}
            >
              {body}
            </Typography>
          </Box>
          <Box flexGrow={1} />
          <Box textAlign="right">
            <img
              alt="Info"
              style={{
                marginTop: 50,
                display: 'inline-block',
                maxWidth: '100%',
                width: '50%',
                opacity: '0.5',
                marginBottom: 100,
              }}
              src={imagePath}
            />
          </Box>
        </Box>
      </Container>
    </Page>

    // <Page className={classes.root} title="Info">
    //   <Box
    //     display="flex"
    //     flexDirection="column"
    //     height="100%"
    //     justifyContent="center"
    //   >
    //     <Container maxWidth="md">
    //       <Typography align="center" color="textPrimary" variant="h1">
    //         {title}
    //       </Typography>
    //       <Typography align="center" color="textPrimary" variant="subtitle2">
    //         {body}
    //       </Typography>
    //       <Box textAlign="center">
    //         <img alt="Info" className={classes.image} src={imagePath} />
    //       </Box>
    //     </Container>
    //   </Box>
    // </Page>
  )
}
