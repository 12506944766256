import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'
import { dismissToast } from './ducks'

export const Toast = () => {
  const dispatch = useDispatch()
  const { on, message, severity, autoHideDuration } = useSelector(
    (state) => state.toast
  )
  const handleClose = () => dispatch(dismissToast())
  return on ? (
    <Snackbar
      open={on && message.trim() !== ''}
      onClose={() => handleClose()}
      autoHideDuration={autoHideDuration}
    >
      <Alert
        severity={severity}
        variant="filled"
        onClose={handleClose}
        elevation={6}
      >
        {message}
      </Alert>
    </Snackbar>
  ) : null
}

export * from './ducks'
