import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { LOCAL_DEBUG, rootPath } from '../config'
import {
  fetchSession,
  isLoggedIn,
  LoginView,
  PrivateRouter,
  RegisterView,
  RegisterDoneView,
  UserHomeView,
  EmailConfirmation,
  ResetView,
  ResetPasswordDoneView,
  PasswordResetView,
} from '../features/auth'
import { ProfileView } from '../features/auth/components/ProfileView'
import { BlockPage, NoteTaskPage } from '../features/blocks'
import { DashboardView, GuestLayout, UserLayout } from '../features/mainframe'
import { NotePage } from '../features/notes'
import {
  dismissProgress,
  showProgress,
  Progress,
  Toast,
  showToast,
  NotFoundView,
  Confirmation,
} from '../features/utilities'
import { GlobalStyles } from '../styles'

// const rootPath = LOCAL_DEBUG ? '' : '/notes'
// const rootPath = ''

export const App = () => {
  const dispatch = useDispatch()
  const loggedIn = useSelector(isLoggedIn)
  const location = useLocation()
  const { from } = location.state || { from: `${rootPath}/home` }
  useEffect(() => {
    dispatch(fetchSession({}))
  }, [])
  console.log(`loggedIn`, loggedIn)
  console.log(`from`, from)
  return (
    <>
      <GlobalStyles />
      <Progress />
      <Toast />
      <Confirmation />
      <Routes>
        <Route
          path={`${rootPath}/`}
          element={loggedIn ? <Navigate to={from} /> : <GuestLayout />}
        >
          <Route path="login" element={<LoginView />} />
          <Route path="register" element={<RegisterView />} />
          <Route path="registerDone" element={<RegisterDoneView />} />
          <Route path="confirmEmail/:token" element={<EmailConfirmation />} />
          <Route path="resetPassword" element={<ResetView />} />
          <Route path="resetPasswordDone" element={<ResetPasswordDoneView />} />
          <Route path="resetPassword/:token" element={<PasswordResetView />} />
          <Route path="/" element={<Navigate to="login" />} />
          <Route path="404" element={<NotFoundView />} />
          <Route
            path="*"
            element={<Navigate to={`${rootPath}/404`} replace={true} />}
          />
        </Route>
        <Route path={`${rootPath}/home`} element={<UserLayout />}>
          <PrivateRouter path="/" element={<DashboardView />} />
          <PrivateRouter path="notes" element={<NotePage />} />
          {/* <PrivateRouter path="notes/editor" element={<NoteEditor />} />
          <PrivateRouter path="notes/editor/:noteId" element={<NoteEditor />} /> */}
          <PrivateRouter path="tasks" element={<NoteTaskPage />} />
          <PrivateRouter path="settings" element={<ProfileView />} />
          <Route path="help" element={<h3>Help</h3>} />
          <Route path="404" element={<NotFoundView />} />
          <Route
            path="*"
            element={<Navigate to={`${rootPath}/app/404`} replace={true} />}
          />
        </Route>
      </Routes>
    </>
  )
}
