// import Header from './header'
// import Embed from './embed'
// import Paragraph from './paragraph'

import { MarkerTool } from './MarkerTool'
import { Checklist } from './plugins/CheckList'
import { CodeTool } from './plugins/Code'
import { Embed } from './plugins/Embed'
import { Header } from './plugins/Header'
import { List } from './plugins/List'
import { Paragraph } from './plugins/Paragraph'
import { SimpleAudio } from './plugins/SimpleAudio'
import { SimpleImage } from './plugins/SimpleImage'
import { SimpleVideo } from './plugins/SimpleVideo'

// import List from '@editorjs/list'
// import InlineCode from '@editorjs/inline-code'
// import Code from '@editorjs/code'
// import Delimiter from '@editorjs/delimiter'
// import Checklist from '@editorjs/checklist'
// import Raw from '@editorjs/raw'

// import Image from '@editorjs/image';
// import Embed from '@editorjs/embed'
// import Quote from '@editorjs/quote'
// import Marker from '@editorjs/marker'
// import Link from '@editorjs/link'
// import Table from '@editorjs/table'
// import Warning from '@editorjs/warning'
// import Paragraph from '@editorjs/paragraph'
// import SimpleImage from '@editorjs/simple-image'

export const commonTools = {
  header: {
    class: Header,
    config: {
      placeholder: 'Enter a Title...',
    },
  },
  paragraph: {
    class: Paragraph,
    inlineToolbar: ['link', 'bold', 'italic', 'marker'],
    config: {
      placeholder: 'Start typing here...',
    },
  },
  embed: Embed,
  image: SimpleImage,
  video: SimpleVideo,
  audio: SimpleAudio,
  list: {
    class: List,
    inlineToolbar: ['marker', 'bold', 'italic'],
  },
  code: {
    class: CodeTool,
    inlineToolbar: ['marker'],
  },
  marker: MarkerTool,
  // checklist: Checklist,
  // inlineCode: InlineCode,
  // raw: Raw,
  // delimiter: Delimiter,

  // image: Image,
  // quote: Quote,
  // marker: Marker,
  // link: Link,
  // table: Table,
  // warning: Warning,
}
