import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from './app'
import { store } from './app/store'
import { Provider } from 'react-redux'
// import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from '@material-ui/styles'
import { theme } from './styles'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
