import { Box, Button, Container, Link, Typography } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { useDispatch } from 'react-redux'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import * as Yup from 'yup'
import { rootPath } from '../../../config'
import { FormPage } from '../../../styles'
import { signupUser } from '../ducks'

// const validationSchema = Yup.object({
//   username: Yup.string().min(3).max(254).required(),
//   email: Yup.string().email().max(254).required(),
//   password: Yup.string().min(8).max(128).required(),
//   passwordConfirmation: Yup.string()
//     .oneOf([Yup.ref('password'), null], 'Password must match')
//     .required(),
//   passwordConfirmation: Yup.string()
//     .oneOf([Yup.ref('password'), null], 'Password must match')
//     .required(),
// })

const validationSchema = Yup.object({
  username: Yup.string().min(3).max(255).required(),
  email: Yup.string().email().max(255).required(),
  password: Yup.string().min(8).max(128).required(),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password must match')
    .required(),
})

export const RegisterView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <FormPage title="Sign Up">
      <Container maxWidth="sm">
        <Formik
          initialValues={{
            username: '',
            email: '',
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) =>
            dispatch(
              signupUser({
                user: values,
                done: () => navigate(`${rootPath}/registerDone`),
              })
            )
          }
        >
          <Form>
            <Box mb={3}>
              <Typography color="textPrimary" variant="h2">
                Create New Account
              </Typography>
              <Typography color="textPrimary" variant="body2" gutterBottom>
                Use your email to create new account
              </Typography>
            </Box>

            <Field
              name="username"
              type="text"
              label="User Name"
              component={TextField}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <Field
              name="email"
              type="email"
              label="Email"
              component={TextField}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <Field
              name="password"
              type="password"
              label="Password"
              component={TextField}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <Field
              name="passwordConfirmation"
              type="password"
              label="Re-Enter Password"
              component={TextField}
              fullWidth
              variant="outlined"
              margin="normal"
            />
            <Box my={2}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign Up Now
              </Button>
            </Box>
            <Typography color="textSecondary" variant="body1">
              Have an account?{' '}
              <Link component={RouterLink} to="../login" variant="h6">
                Sign In
              </Link>
            </Typography>
          </Form>
        </Formik>
      </Container>
    </FormPage>
  )
}
